import './IntroComp.css';
import api from '../../api/axiosConfig';
import { useEffect, useState } from 'react';
import { redirect, useNavigate, Link } from "react-router-dom";

import logo from '../../images/shiny_symbol_withcircle1.png';
import Partnership_Pic from '../../images/Traumwelten Lektorat6.png';


//Security
import { SanitizeInput } from '../../tools/security/SanitizeInput';
import { sanitizeInputTextArea } from '../../tools/security/SanitizeInputTextArea';
import { sanitizeInputTextAreaBig } from '../../tools/security/SanitizeInputTextAreaBig';
import { exeedsMaxCharacter } from '../../tools/security/exeedsMaxCharacter';
import { exeedsMaxCharacterTextArea } from '../../tools/security/exeedsMaxCharacterTextArea';
import { exeedsMaxCharacterTextAreaBig } from '../../tools/security/exeedsMaxCharacterTextAreaBig';



const IntroComp = ({showQuiz, setShowQuiz}) => {


  const [render, setRender] = useState(false);    
  const [showRegisterWaitingList, setShowRegisterWaitingList] = useState(false);


  const [entityName, setEntityName] = useState('');
  const [entityQuestion1, setEntityQuestion1] = useState('');
  const [entityQuestion2, setEntityQuestion2] = useState('');
  const [entityQuestion3, setEntityQuestion3] = useState('');
  const [entityQuestion4, setEntityQuestion4] = useState('');
  const [entityQuestion5, setEntityQuestion5] = useState('');
  const [entityQuestion6, setEntityQuestion6] = useState('');

  const [error, setError] = useState(null);
  

    const handleQuestion1Change = (event) => {
      try {
        const input = exeedsMaxCharacterTextArea(event.target.value);
        setEntityQuestion1(input);
      } catch (error) {
        handleError('Error handling Question1 change:', error);
      }
    };
      
    const handleQuestion2Change = (event) => {
      try {
        const input = exeedsMaxCharacterTextArea(event.target.value);
        setEntityQuestion2(input);
      } catch (error) {
        handleError('Error handling Question1 change:', error);
      }
    };
    
    const handleQuestion3Change = (event) => {
      try {
        const input = exeedsMaxCharacterTextArea(event.target.value);
        setEntityQuestion3(input);
      } catch (error) {
        handleError('Error handling Question1 change:', error);
      }
    };

    const handleQuestion4Change = (event) => {
      try {
        const input = exeedsMaxCharacterTextArea(event.target.value);
        setEntityQuestion4(input);
      } catch (error) {
        handleError('Error handling Question1 change:', error);
      }
    };


    const handleQuestion5Change = (event) => {
      try {
        const input = exeedsMaxCharacterTextArea(event.target.value);
        setEntityQuestion5(input);
      } catch (error) {
        handleError('Error handling Question1 change:', error);
      }
    };

    const handleQuestion6Change = (event) => {
      try {
        const input = exeedsMaxCharacterTextArea(event.target.value);
        setEntityQuestion6(input);
      } catch (error) {
        handleError('Error handling Question1 change:', error);
      }
    };


    const entityHandleSubmit = async (e) => {
      try {
        e.preventDefault();
    
        const entityNameVar = sanitizeInputTextArea(entityName);
        const question1Var = sanitizeInputTextArea(entityQuestion1);
        const question2Var = sanitizeInputTextArea(entityQuestion2);
        const question3Var = sanitizeInputTextArea(entityQuestion3);
        const question4Var = sanitizeInputTextArea(entityQuestion4);
	const question5Var = sanitizeInputTextArea(entityQuestion5);
	const question6Var = sanitizeInputTextArea(entityQuestion6);

    
        const addNewEntityData = {
          entityName: entityNameVar,
          question1: question1Var,
          question2: question2Var,
          question3: question3Var,
          question4: question4Var,
	        question5: question5Var,
	        question6: question6Var,

        };
    
        const response = await api.post("/api/waitingListMembers", addNewEntityData);

	if (response.data != null && !response.data.isEmpty) {
        setShowRegisterWaitingList(true);         
        }else{
	   handleError('Error submitting Entity data:', error);
     setShowRegisterWaitingList(false);
	}    

      } catch (error) {
        handleError('Error submitting Entity data:', error);
      }
    };


    const rerender = () => {
      setRender(true);
    };
    
    const quizBtnClick = (e) => {
      try {
        	e.preventDefault();  
        	const { target } = e;
		const inputValue = exeedsMaxCharacter(target.userName.value);
        	setEntityName(inputValue);
      		setShowQuiz(true);
          window.scrollTo({ top: 0, behavior: 'smooth' });
      } catch (error) {
        handleError('Error during waitingList registration:', error);
      }
    };
    
      useEffect(() => {
          setShowRegisterWaitingList(false);
	  setShowQuiz(false);
      }, []);
            

    const handleError = (message, error) => {
      console.error(message, error);
      setError('An error occurred. Please try again.');
      };


  return (
    <div className="IntroComp">
     <div className="w-full ">
        <div className="row row-col-12 text-center m-4">
              {/*}  {error && <div style={{ color: 'red' }}>{error}</div>}     */}
	      </div>

      <div className=" "> 
        <div className=" ">

        {showRegisterWaitingList && showQuiz && (
        <div>
        <div className='blurPage z-20 fixed inset-0 h-screen w-screen overflow-hidden'></div>
        <div className='loadingBox p-4 max-sm:mt-20 max-sm:w-4/5 compThreeBGColor white-glassmorphism-card flex flex-col items-center justify-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30' style={{ minWidth: '250px', maxWidth: '450px' }}>
          <div className="w-full text-center">

            <div className="col-sm-2 text-center"></div>
            <div className="col-sm-8 text-center colConfirmEmail">
            <div className="col-sm-12 items-center justify-content-center">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-8"><img className="scale-50" src={logo} alt="logo" /></div>
                        <div className="col-sm-2"></div>
                  </div>
              <h3 className="text-white mt-1 mb-5 pb-1">Du hast dich erfolgreich der Warteliste hinzugefügt!</h3>
              <div className="text-center pt-1 mb-5 pb-1">
                  <button className="buttonTextColor text-2xl p-2 px-32 mx-4 btnChange mb-3" type="button" onClick={() => { setShowRegisterWaitingList(false); setShowQuiz(false) }}>OK</button>
              </div>
            </div>
            <div className="col-sm-2 text-center"></div>
          </div>
          </div>
       </div>
    
       )}

{!showRegisterWaitingList && !showQuiz && (

          <div className="justify-center landing-bg text-center items-center h-full ">

            <div className="text-white">  
          <div className='row  -mt-20 mb-0 pt-40 justify-center items-center'>
                    <div className='col-sm-12 mt-0 mb-10 landingColor'>
                    <div className='row'>
                            <div className="col col-sm-6">
                            <h1 className="mb-10 -mt-20 sm:ml-10 pt-20 font-semibold 
                            sm:text-left max-sm:text-center text-black sm:text-8xl max-sm:text-7xl">Der Marktplatz für <br></br>Buch-Professionals</h1>
                            </div>
                            <div className="col col-sm-6"></div>
                        </div>          
                    </div>
            </div>
            <div className='row  mt-80 mb-0 pt-0 justify-center items-center'>
                    <div className='col-sm-12 mt-40 mb-10 landingColor'>
                    <div className='row'>
                            <div className="col col-sm-5"></div>
                            <div className="col col-sm-7">
                                <h3 className="mb-10 text-center font-bold sm:text-white max-sm:text-black max-sm:pb-40" >
                                    Wir bringen Kreative, Dienstleister und Verleger der Buch-Industrie zusammen, einfach und schnell!
                                    <br></br>Sei dabei mit deinem Manuskript bis zur Veröffentlichung.
                                  </h3>
                              </div>
                        </div>          
                    </div>
            </div>

            </div>

            <div className='col col-sm-12 w-full  compThreeBGColor'>
                <div className="col col-sm-6 w-full p-8 mb-0">
                          <h2 className="mb-8 text-center landingColor gradient-textLanding  font-bold">Tritt der Warteliste bei!</h2>
                  <form onSubmit={quizBtnClick} className="mb-8 flex flex-col items-center justify-center">
                    <p className="mb-4 text-3xl text-white" style={{ minWidth: '330px',maxWidth: '500px'}}>Melde dich auf unserer Warteliste an, um über Neuigkeiten informiert zu werden und Vorteile beim Launch zu erhalten!</p>
                    <div className="mb-4 inputSize300 ">
                      <input type="email" id="form2Example11" className="w-full text-black px-4 py-2 border rounded-md  " 
                            placeholder="Email-Adresse" name="userName"/>
                    </div>
                    <div className="text-center ">
                      <button className="w-full inputSize300 text-4xl py-4 px-34 btnLanding hover:text-black hover:bg-yellow-100" type="submit">Warteliste anmelden</button>
                    </div>
                  </form>
                </div> 
                <div className="col col-sm-6 max-sm:pb-28 justify-center gradient-textLanding ">
                        <h3 className="mb-8 mt-40 text-center text-white">BETA - Version</h3>
                        <h3 className="mb-8 text-center text-white">Gelauncht wird in den nächsten zwei Wochen!</h3>
                </div>
            </div>

            <div className="col col-sm-12 w-full pt-28 gradient-bluumer">
              <h4 className="mb-8 text-center font-bold text-gray-600">UNSERE VERLÄSSLICHEN PARTNER AUS DEM BUCH-PROFESSIONAL BEREICH</h4>
              <div className="flex justify-center items-center">
                <img className='m-5' src={Partnership_Pic} alt="Partnership_Pic" style={{ maxHeight: '200px', minHeight: '50px' }} />
              </div>
            </div> 
          </div>
)}

          </div> 

          <div className="row w-full ">
          {!showRegisterWaitingList && showQuiz && (
      <><div className='col-sm-12 mb-40'>
       <div>
       {/*<div className='blurPage z-20 fixed inset-0 h-screen w-screen overflow-hidden'></div> 
              <div className='loadingBox max-sm:mt-20 max-sm:w-4/5 p-10 compThreeBGColor flex flex-col fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30' 
                style={{ minWidth: '320px', maxWidth:'1400px' , maxHeight:'480px', overflowY: 'auto'}}> */}
                <div className='loadingBox max-sm:mt-20 max-sm:w-4/5 p-10 compThreeBGColor flex flex-col fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ' 
                style={{ minWidth: '320px', maxWidth:'1400px' , maxHeight:'480px', overflowY: 'auto'}}>
               
                <div className="w-full text-white">
                
                  <div className='flex col-sm-12 justify-center items-center'>
                    <div className='col-sm-8 text-left'><h2 className="FormStyling text-left gradient-textLanding text-5xl max-sm:pl-4 max-sm:pb-2">Warteliste Anmeldung</h2></div>
                    <div className='col-sm-4'><button className="text-right px-12 py-2 btnLanding hover:text-black hover:bg-yellow-100 rounded sm:mr-2 font-bold" onClick={()=> {setShowRegisterWaitingList(false); setShowQuiz(false)}}>zurück</button></div>
                  </div>
                       <p>Bitte beantworte uns kurz noch ein paar Fragen.</p>		
                    <hr className='horizontalLine' />
                    <form onSubmit={entityHandleSubmit} method="POST">
                        <div className="row">
                          <div className='col col-sm-12 col-md-12'>
                            <div className='col  col-sm-12 col-md-3 '><h3 className="FormStyling min-sm:text-left waitingListColor">Frage 1</h3></div>
                            <div className='col  col-sm-12 col-md-9 min-sm:pt-10 sm:pt-10 text-3xl'><p>Welche Profile der Plattform interessieren dich am meisten? (Lektor/Korrektor, Autor, Testleser, Verlag, CoverDesigner)</p></div>
			                    </div>
                        </div>
                        <div className="row ">
                          <div className="col col-sm-12 text-left">
                            <div className="row textAreaOne">    
                              <textarea
                                className="FormStyling  w-full flex-grow-1 text-black"
                                placeholder={'Frage 1'}
                                name="entityQuestion1"
                                style={{ overflowY: 'auto', maxHeight: '500px', minHeight: '50px', minWidth: '250px' }}
                                value={entityQuestion1}
                                onChange={handleQuestion1Change} />
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className='col col-sm-12'>
                            <div className='col  col-sm-3'><h3 className="FormStyling min-sm:text-left waitingListColor">Frage 2</h3></div>
                            <div className='col  col-sm-9 min-sm:pt-10 sm:pt-10 text-3xl'><p>Wofür möchtest du die Plattform am meisten benutzen?</p></div>
			                  </div>
                        </div>
                        <div className="row ">
                          <div className="col col-sm-12 text-left   ">
                            <div className="row textAreaOne">    
                              <textarea
                                className="FormStyling w-full flex-grow-1 text-black"
                                placeholder={'Frage 2'}
                                name="entityQuestion2"
                                style={{ overflowY: 'auto', maxHeight: '500px', minHeight: '50px', minWidth: '250px' }}
                                value={entityQuestion2}
                                onChange={handleQuestion2Change} />
                            </div>
                          </div>
                        </div>

                        <div className="row  ">
                          <div className='col col-sm-12'>
                              <div className='col  col-sm-3'><h3 className="FormStyling min-sm:text-left waitingListColor">Frage 3</h3></div>
                              <div className='col  col-sm-9 min-sm:pt-10 sm:pt-10 text-3xl'><p>Welche Probleme hattest du im Zusammenhang mit Büchern, für die du keine zufriedenstellende Lösung gefunden hast?</p></div>
			                   </div>
                        </div>
                        <div className="row ">
                          <div className="col col-sm-12 text-left  ">
                            <div className="row textAreaOne">    
                              <textarea
                                className="FormStyling w-full flex-grow-1 text-black"
                                placeholder={'Frage 3'}
                                name="entityQuestion3"
                                style={{ overflowY: 'auto', maxHeight: '500px', minHeight: '50px', minWidth: '250px' }}
                                value={entityQuestion3}
                                onChange={handleQuestion3Change} />
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className='col col-sm-12'>
                              <div className='col  col-sm-3'><h3 className="FormStyling min-sm:text-left waitingListColor">Frage 4</h3></div>
                              <div className='col  col-sm-9 min-sm:pt-10 sm:pt-10 text-3xl'><p>Wie lange hast du dieses Problem schon?</p></div>
			                    </div>
                        </div>
                        <div className="row">
                          <div className="col col-sm-12 text-left  ">
                            <div className="row textAreaOne">    
                              <textarea
                                className="FormStyling w-full flex-grow-1 text-black"
                                placeholder={'Frage 4'}
                                name="entityQuestion4"
                                style={{ overflowY: 'auto', maxHeight: '500px', minHeight: '50px', minWidth: '250px' }}
                                value={entityQuestion4}
                                onChange={handleQuestion4Change} />
                            </div>
                          </div>
                        </div>
                    
                        <div className="row  ">
                          <div className='col col-sm-12'>
                              <div className='col  col-sm-3'><h3 className="FormStyling min-sm:text-left waitingListColor">Frage 5</h3></div>
                              <div className='col  col-sm-9 min-sm:pt-10 sm:pt-10 text-3xl'><p className=''>Wie viel wäre es dir im Monat wert, dein Problem zu lösen?</p></div>
			                    </div>
                        </div>
                        <div className="row ">
                          <div className="col col-sm-12 text-left ">
                            <div className="row textAreaOne">    
                              <textarea
                                className="FormStyling w-full flex-grow-1 text-black"
                                placeholder={'Frage 5'}
                                name="entityQuestion5"
                                style={{ overflowY: 'auto', maxHeight: '500px', minHeight: '50px', minWidth: '250px' }}
                                value={entityQuestion5}
                                onChange={handleQuestion5Change} />
                            </div>
                          </div>
                        </div>
                        <div className="row   ">
                          <div className='col col-sm-12'>
                              <div className='col  col-sm-3'><h3 className="FormStyling min-sm:text-left waitingListColor">Frage 6</h3></div>
                              <div className='col  col-sm-9 min-sm:pt-10 sm:pt-10 text-3xl'><p>Was erhoffst du dir von der Plattform?</p></div>
			                    </div>
                        </div>
                        <div className="row ">
                          <div className="col col-sm-12 text-left  ">
                            <div className="row textAreaOne">    
                              <textarea
                                className="FormStyling w-full flex-grow-1 text-black"
                                placeholder={'Frage 6'}
                                name="entityQuestion6"
                                style={{ overflowY: 'auto', maxHeight: '500px', minHeight: '50px', minWidth: '250px' }}
                                value={entityQuestion6}
                                onChange={handleQuestion6Change} />
                            </div>
                          </div>
                        </div>
		                    <div className="col FormStyling text-center justify-center items-center">
                        <button className=" max-sm:px-20 px-48 py-2 btnLanding hover:text-black hover:bg-yellow-100 rounded-md mr-2 font-bold">Warteliste beitreten</button>
                      </div> 
 
                </form>
              </div>
            </div>   
          </div>
       </div>
            </>                 
        )}
     


      </div>
      
    </div>   
  </div> 
</div>              
         
    
    
  );
}

export default IntroComp; 
export { default as IntroComp } from './IntroComp';