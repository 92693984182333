
import api from '../../api/axiosConfig.js';
import './ForumComp.css';

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import { ImHappy } from "react-icons/im";
import { ImCross } from "react-icons/im";
import { ImSearch } from "react-icons/im";

import { MdThumbUpAlt } from "react-icons/md";
import { FaPencilAlt } from "react-icons/fa"

import { CgChevronDown } from "react-icons/cg";
import { CgChevronRight } from "react-icons/cg";

import {TimestampFormatter} from '../../tools/TimestampFormatter.js';
import {TimestampFormatterLight} from '../../tools/TimestampFormatterLight.js';
import {ShortTimestampFormatter} from '../../tools/ShortTimestampFormatter.js';
import {TrimTalkName} from '../../tools/TrimTalkName.js';
import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';

//Security
import { SanitizeInput } from '../../tools/security/SanitizeInput';
import { sanitizeInputTextArea } from '../../tools/security/SanitizeInputTextArea';
import { exeedsMaxCharacter } from '../../tools/security/exeedsMaxCharacter';


  function shortenText(text, maxLength) {
	if (text.length <= maxLength) {
	  return text;
	} else {
	  return text.substring(0, maxLength) + '...';
	}
  }

 function IconWithTooltip2({ icon, tooltipText }) {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div
        className=""
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {React.cloneElement(icon, {
          //style: { fontSize: '' },
          title: isHovered ? tooltipText : '',
        })}
      </div>
    );
  }

const ForumComp = ({forumState, setForumState}) => {

	const [getSingleProfileData, setSingleProfileData] = useState();
	const [searchInputValue, setSearchInputValue, ] = useState([]);
	const [getTalksData, setTalksData, ] = useState([]);
	const [getShowSingleTalkMessages, setShowSingleTalkMessages] = useState();
	
	const [isProfileName, setIsProfileName] = useState();
	const [getPrevTimestamp, setPrevTimestamp] = useState(null);
	const [showEmoji, setShowEmoji] = useState(false);
	const [showEmojiTwo, setShowEmojiTwo] = useState(false);
	const [text, setText] = useState('');

	const [getImgSrc, setImgSrc] = useState('');

	const [getTopicAlreadyCreated, setTopicAlreadyCreated] = useState(false);
	//const [getForumEntityName, setForumEntityName] = useState('');

	const [getAllTopics, setAllTopics] = useState([]);
	const [getAllTopicsWhereInvolved, setAllTopicsWhereInvolved] = useState([]);
	const [getAllTopicsFromCreator, setAllTopicsFromCreator] = useState([]);

	const [getSingleTopicData, setSingleTopicData] = useState([]);

	const [getAnswerBoxVisible, setAnswerBoxVisible] = useState(false);
    	const [getChosenTopicName, setChosenTopicName] = useState('');
    	const [getChosenTopicType, setChosenTopicType] = useState('');
    	const [createAnswerisClicked, setCreateAnswerisClicked] = useState([]);
	const [createAnswerInTopicIsClicked, setCreateAnswerInTopicIsClicked] = useState(false);

        const [getAnswerIsClickedId, setAnswerIsClickedId] = useState('');
    
	const [entityName, setEntityName] = useState('');
	const [showCreateTopic, setShowCreateTopic] = useState(false);
	const [showSearchRow, setShowSearchRow] = useState(false);

	
	const [error, setError] = useState(null);

	const forumRef = useRef();
	
///////////////////////////////// Logout redirect //////////////////////////7

const navigate = useNavigate();

useEffect(() => {
  LogoutRedirectCheck(navigate);
}, [showEmoji,showEmojiTwo, createAnswerisClicked, getTalksData ]);

///////////////////////////////// Logout redirect //////////////////////////7

 

  const handleSearchInputChange = async (e) => {
	const sanitizedValue = SanitizeInput(e.target.value);
	setSearchInputValue(sanitizedValue);
  };

  const handleTopicNameChange = (e) => {
    		const inputValue = exeedsMaxCharacter(e.target.value);
		const sanitizedValue = SanitizeInput(inputValue);
    		setEntityName(sanitizedValue);
    };

 

  let isCreatingTopic = false;
  
  const createTopic = async () => {

	if (isCreatingTopic) return; // Exit if the function is already running
	isCreatingTopic = true;
	
	setShowCreateTopic(!showCreateTopic);
  
	try {
	  const response = await api.get(`/api/topics`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
	  });
  
	  const isTopicExists = response.data.some(
		(topics) =>
		  topics.topicName === entityName,
		 
	  );

	const newTopicData = {
	  createdFromName: localStorage.getItem('profileName'),
	  topicName: entityName,
	};

	//console.log('isTopicExists: ', isTopicExists);
  
	  if (!isTopicExists) {
		const response2 = await api.post('/api/topics/create', newTopicData, {
		  headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
		});

  
		if (response2 == null || response2.isEmpty) {
		  console.log('Topic could not be created');
		}
	  } else {
		setTopicAlreadyCreated(true);
		//console.log('Topic exists already');
	  }

	  fetchData();
	} catch (error) {
	  handleError('Error creating talk:', error);
	
	} finally {
    isCreatingTopic = false; // Reset the flag
  }
  };
  
  const fetchAllTopics = async () => {
  
	try {
	  const response = await api.get(`/api/topics`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
	  });
	  setForumState(false);
	  setAllTopics(response.data);
	  return response.data;
	} catch (err) {
	  handleError('Error fetching AllTopics:', err);
	}
  };

  const fetchAllTopicsFromCreator = async () => {
	const userProfileName = localStorage.getItem('profileName');
  
	try {
	  const response = await api.get(`/api/topics/allTopicsFromCreator/${userProfileName}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
	  });
	  console.log("AllTopicsFromCreator: ", response.data);
	  setAllTopicsFromCreator(response.data);
	  return response.data;
	} catch (err) {
	  handleError('Error fetching AllTopicsFromCreator:', err);
	}
  };

  const fetchAllTopicsWhereInvolved = async () => {
	const userProfileName = localStorage.getItem('profileName');
  
	try {
	  const response = await api.get(`/api/topics/allTopicsForUserProfile/${userProfileName}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
	  });
	  console.log("allTopicsWhereInvolved: ", response.data);
	  setAllTopicsWhereInvolved(response.data);
	  return response.data;
	} catch (err) {
	  handleError('Error fetching AllTopicsWhereInvolved:', err);
	}
  };


   const getSingleTopic = async (e) => {
        e.preventDefault();
        const { target } = e;
      
        const addNewAuthorData = {
          topicName: target.name.value,
        };
      
		//console.log("response.data: ", target.name.value);
        try {

          const response = await api.get(`/api/topics/search/${addNewAuthorData.topicName}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });

          //console.log("response.data: ", response.data);
      
          setSingleTopicData(response.data[0]);
		  setShowSearchRow(true);

        } catch (error) {
          handleError('Error fetching single author:', error);
        }
      };

	  const deleteSearchSingleTopic = () => {
        setSearchInputValue('');
        setShowSearchRow(false);
      };
  

  const deleteTopic = async (topicName) => {
	try {
	  const response2 = await api.delete(`/api/topics/delete/${topicName}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
	  });
  
	  if (response2 == null || response2.isEmpty) {
		console.log("Topic could not be deleted");
	  }
	  fetchData();
	} catch (error) {
		handleError('Error deleting topic:', error);
	}
  };


  const textAreaRef = useRef(null);


	const createAnswer = async (e) => {
		e.preventDefault();	  
		
        	setCreateAnswerisClicked(false, false);
		setCreateAnswerInTopicIsClicked(false);
		setShowEmoji(false);
		setShowEmojiTwo(false);

    const responseToAnswerId = textAreaRef.current.getAttribute('data-response-to-answer-id');
    const topicName = textAreaRef.current.getAttribute('data-topic-name');

		console.log('responseToAnswerId:', responseToAnswerId);
		console.log('topicName:', topicName);

		const newAnswerData = {
		  answerSenderName: localStorage.getItem('profileName'),
		  topicName: topicName,   //getChosenTopicName,
		  answerText: text,
		  userProfileName: localStorage.getItem('profileName'),
		  responseToAnswerId: responseToAnswerId,
		};
	  
		try {
		  const response = await api.post('/api/answer/create', newAnswerData, {
			headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
		  });	  
	  
		  if (response === null || response.isEmpty) {
			console.log("Answer could not be created");
		  }

		  fetchData();
		} catch (error) {
			handleError('Error creating answer:', error);
		}
	  };


	const updateHelpfulCount = async (randomIdNumber, answerSenderName) => {

		const newAnswerData = {
			randomIdNumber: randomIdNumber,
			answerSenderName: answerSenderName,
		  userProfileName: localStorage.getItem('profileName')
		};
	  
		try {
		  const response = await api.post(`/api/answer/update/helpfulCount`, newAnswerData, {
			headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
		  });	  
	  
		  if (response === null || response.isEmpty) {
			console.log("HelpfulCount could not be updated");
		  }

		  fetchData();
		} catch (error) {
			handleError('Error updating HelpfulCount:', error);
		}
	  };


  const deleteAnswer = async (randomIdNumber, answerGivenAnswerRandomIdNumber) => {
	
		const newAnswerDeleteData = {
		 randomIdNumber: randomIdNumber,
		  userProfileName: localStorage.getItem('profileName'),
		  answerGivenAnswerId: answerGivenAnswerRandomIdNumber,
		};


	try {

		const response2 = await api.delete('/api/answer/delete', {
			headers: { 
			  Authorization: `Bearer ${localStorage.getItem('logintoken')}` 
			},
			data: newAnswerDeleteData
		  });
  
	  if (response2 == null || response2.isEmpty) {
		console.log("Answer could not be deleted");
	  }
	  fetchData();
	} catch (error) {
		handleError('Error deleting answer:', error);
	}
  };



	  const getLastAnswerTime = (topic) => {
		try {
		  if (topic.answerIds.length > 0) {
			const lastAnswerCreatedAtArray = topic.answerIds[topic.answerIds.length - 1]?.answerCreatedAt;
	  
			if (lastAnswerCreatedAtArray && Array.isArray(lastAnswerCreatedAtArray) && lastAnswerCreatedAtArray.length === 7) {
			  const [year, month, day, hour, minute, second, millisecond] = lastAnswerCreatedAtArray;
	  
			  if (!isNaN(year) && !isNaN(month) && !isNaN(day) && !isNaN(hour) && !isNaN(minute) && !isNaN(second) && !isNaN(millisecond)) {
				return [year, month, day, hour, minute, second, millisecond];
			  }
			}
		  } else {
			const topicCreatedAtArray = topic.topicCreatedAt;
	  
			if (Array.isArray(topicCreatedAtArray) && topicCreatedAtArray.length === 7) {
			  const [year, month, day, hour, minute, second, millisecond] = topicCreatedAtArray;
	  
			  if (!isNaN(year) && !isNaN(month) && !isNaN(day) && !isNaN(hour) && !isNaN(minute) && !isNaN(second) && !isNaN(millisecond)) {
				return [year, month, day, hour, minute, second, millisecond];
			  }
			}
		  }
	  
		  return undefined;
		} catch (error) {
		  handleError('Error getting last answer time:', error);
		  return undefined;
		}
	  };
	  
	  
	  const sortedAllTopics = getAllTopics.slice().sort((a, b) => {
		try {
		  const timeA = a.topicCreatedAt;
		  const timeB = b.topicCreatedAt;
	  
		  if (timeA === undefined || timeB === undefined) {
			return 0;
		  }
	  
		  return (
			timeB[0] - timeA[0] ||
			timeB[1] - timeA[1] ||
			timeB[2] - timeA[2] ||
			timeB[3] - timeA[3] ||
			timeB[4] - timeA[4] ||
			timeB[5] - timeA[5] ||
			timeB[6] - timeA[6]
		  );
		} catch (error) {
		 handleError('Error sorting AllTopics:', error);
		 return [];
		}
	  });


	  const sortedAllTopicsFromCreator = getAllTopicsFromCreator.slice().sort((a, b) => {
		try {
		  const timeA = a.topicCreatedAt;
		  const timeB = b.topicCreatedAt;
	  
		  if (timeA === undefined || timeB === undefined) {
			return 0;
		  }
	  
		  return (
			timeB[0] - timeA[0] ||
			timeB[1] - timeA[1] ||
			timeB[2] - timeA[2] ||
			timeB[3] - timeA[3] ||
			timeB[4] - timeA[4] ||
			timeB[5] - timeA[5] ||
			timeB[6] - timeA[6]
		  );
		} catch (error) {
		 handleError('Error sorting AllTopicsFromCreator:', error);
		 return [];
		}
	  });


	  const sortedAllTopicsWhereInvolved = getAllTopicsWhereInvolved.slice().sort((a, b) => {
		try {
		  const timeA = a.topicCreatedAt;
		  const timeB = b.topicCreatedAt;
	  
		  if (timeA === undefined || timeB === undefined) {
			return 0;
		  }
	  
		  return (
			timeB[0] - timeA[0] ||
			timeB[1] - timeA[1] ||
			timeB[2] - timeA[2] ||
			timeB[3] - timeA[3] ||
			timeB[4] - timeA[4] ||
			timeB[5] - timeA[5] ||
			timeB[6] - timeA[6]
		  );
		} catch (error) {
		 handleError('Error sorting AllTopicsWhereInvolved:', error);
		 return [];
		}
	  });

const topicLists = {
  '1': sortedAllTopics,
  '2': sortedAllTopicsFromCreator,
  '3': sortedAllTopicsWhereInvolved,
  '4': sortedAllTopics,
};


const addEmoji = (e) => {
  try {
    const sym = e.unified.split('_');
    const codeArray = sym.map((el) => parseInt(el, 16));

    if (codeArray.some(isNaN)) {
      console.error('Invalid hexadecimal values in codeArray:', codeArray);
      return;
    }

    let emoji = String.fromCodePoint(...codeArray);
    setText(text + emoji);
  } catch (error) {
    handleError('Error adding emoji:', error);
}
};



const handleTopicClick = (topicName, topicType) => {
	try {
	  setAnswerBoxVisible(true);
      	  setChosenTopicName(topicName);
	  setChosenTopicType(topicType);
	} catch (error) {
		handleError('Error handling topic click:', error);
}
  };




    
  const fetchData = async () => {
	try {
	  if (localStorage.getItem('logintoken') === 'Please Log in') {
		navigate('/');
	  } else {
		const Topics = await fetchAllTopics();
		setAllTopics(Topics);
		const TopicsWhereInvolved = await fetchAllTopicsWhereInvolved();
		setAllTopicsWhereInvolved(TopicsWhereInvolved);
		const TopicsFromCreator = await fetchAllTopicsFromCreator();
		setAllTopicsFromCreator(TopicsFromCreator);
	  }
	} catch (error) {
	  handleError('Error fetching data:', error);
	}
  };



	const answerIsCLicked = (timestamp) => {

		if(getAnswerIsClickedId === ''){
			setAnswerIsClickedId(timestamp);	
		}else{
			if(getAnswerIsClickedId === timestamp){
				setAnswerIsClickedId('');	
			}else{
				setAnswerIsClickedId(timestamp)
			}
		}

	}; 


  
  
  useEffect(() => {
	try {
	  fetchData();

	  const fetchInterval = setInterval(fetchData, 20 * 1000);
  
	  // Cleanup the timer when the component unmounts
	  return () => clearInterval(fetchInterval);
	} catch (error) {
	  handleError('Error setting up fetch interval:', error);
	}
  }, []);
  
  useEffect(() => {
	try {
	  setIsProfileName(localStorage.getItem('profileName'));
	  setShowEmoji(false);
	  setShowEmojiTwo(false);
	  setImgSrc(`http://localhost:8080/api/files/image/`);
	  
	} catch (error) {
	  handleError('Error setting up initial state:', error);
	}
  }, []);



  const handleError = (message, error) => {
	console.error(message, error);
	setError('An error occurred. Please try again.');
  };



  return(

<div className="ForumComp w-full min-h-full py-20  z-50" style={{ minHeight: "400px" }}>
  <div className="container-sm forumCol h-full">
  {/*  {error && <div style={{ color: 'red' }}>{error}</div>}  */}
  {/* {getDashboardChatAlreadyCreated && (<p className='mb-8' style={{ color: 'red' }}>Das Gespräch existiert bereits!</p>)} */}


{ !getAnswerBoxVisible && (

	<>
      <div className={`  rounded-xl`}>
      { ( getTopicAlreadyCreated ) && (    
	  <div className=' borderOneNew my-1 topicHover rounded-xl  bg-red-300 ' >
            <div className=' '>
              <div className="">
                <>
                  <div className='opacity-100 absolute p-1 top-4 left-2' >

			{getTopicAlreadyCreated && (
                      <>
                        <ImCross className='text-black-900 cursor-pointer ' onClick={() => setTopicAlreadyCreated(false)} />
                      </>
                    )}
                  </div>
                </>
              </div>
		{getTopicAlreadyCreated && ( <h4 className="col-span 4 ml-16 text-left text-left TextBold text-black">Das Thema existiert bereits! Wähle bitte einen anderen Namen.</h4> )}
           
		    </div>
	    </div>
	  )}
   </div>

   <div className='container-sm mx-10'>
  <div className=''>
       <div className=''>

	   <h4 className="TextBold">Bluumer Themen</h4>
   <div className='mb-5 forumBorder  overflow-y-scroll overflow-x-hidden px-3' style={{minHeight: '300px', maxHeight: '300px'}}>
  {/*     ////////////////////    All Topics from Admin    ////////////////////     */}
        <div className=" dashboardDataCard forumTitleRow overflow-scroll white-glassmorphism-card max-sm:hidden">
	  <div className='row'>
             <div className='col-sm-12'>
	     	<div className='col-sm-3'><h5 className="TextBold mobileView LinkColor">Erstellt am</h5></div>
             	<div className='col-sm-6'><h5 className="TextBold mobileView LinkColor">Thema</h5></div>
           
            <div className={`col-sm-3`}>
               <h5 className={`TextBold mobileView2 LinkColor`}>Letzte Aktivität</h5>
	    </div>      
	       {/*}   <div className={`col-sm-2`}><h5 className='mobileView2'>Antworten</h5></div>           
	   <div className={`col-sm-2`}><h5 className='mobileView2'>Profile beteiligt</h5></div>
          */}
	   </div>
	   </div>
	</div>	
	{sortedAllTopics && sortedAllTopics.map((topic) => (
     <> {topic.createdFromName === 'Admin' && (
          <div key={topic.topicName} className={` borderOneNew my-1 topicHover rounded-xl white-glassmorphism-card`} onClick={() => handleTopicClick(topic.topicName, '4')}>
            <div className='row'>
             <div className='col-sm-12'>
	     <div className="relativ p-4 ">
                <>
                 {/*} <div className={`opacity-100 hover:opacity-100 absolute p-1 top-4 left-10`}  >
                    {topic.answerIds.length === 0 && topic.createdFromName(
                      <>
                        <ImCross className='text-black-900 cursor-pointer ' onClick={() => deleteTopic(topic.topicName)} />
                      </>
                    )}
                  </div> */}
                </>
              </div>
	     <div className='col-sm-3 max-sm:flex'><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Erstellt am: </h5><h5 className=" mobileView2 "><ShortTimestampFormatter timestamp={topic.topicCreatedAt}/></h5></div>
             <div className='col-sm-6 max-sm:flex'><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Thema: </h5><h5 className=" mobileView2 ">{topic.topicName}</h5></div>
         
            <div className={`col-sm-3 max-sm:flex`}>
			<h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Letzte Aktivität: </h5>
               <h5 className={`mobileView2`}>{topic.answerIds.length > 0?<ShortTimestampFormatter timestamp={topic.answerIds[topic.answerIds.length - 1].answerCreatedAt} />:'0'}</h5>
	    </div>      
	    {/*}   <div className={`col-sm-2`}><h5 className='mobileView2'>{topic.answerIds.length}</h5></div>           
	    <div className={`col-sm-2`}><h5 className='mobileView2'>{topic.userProfilesWhoAnsweredIds.length}</h5></div>
		*/}
		</div>
		  </div>
          </div>
     )}
   </>
  ))}

</div>
</div>
		  </div>
          </div>


<div className='row'>
  <div className='col-sm-12 '>
       <div className=''>
	   	<div className='col-sm-12 col-lg-8'>  			

      {/* First Block - 70% width, spanning 2 rows */}
	  <h4 className="TextBold">Alle Themen</h4>

      <div className='sm:ml-5 px-3 mb-6 forumBorder' style={{minHeight: '645px', maxHeight: '645px'}}> 
		<div className='' >

      <div className={`col mb-5 mt-3  borderOneNew forumTitleRowReverse white-glassmorphism-card rounded-xl`}>
        <div className='row mb-3'>
		<div className="col-sm-2 sm:hidden" style={{ minWidth: 40 }}><h4 className="TextBold text-white mt-5">SUCHE</h4></div>
          
		<div className="col-sm-12 max-sm:flex">
          <div className="col-sm-2 max-sm:hidden" style={{ minWidth: 40 }}><h4 className="TextBold text-white mt-5">SUCHE</h4></div>
          <div className="col-sm-8 max-sm:col-sm-10">
            <form onSubmit={getSingleTopic} className='flex justify-between'>
				<div className="w-full">
					<input
						className="border w-full sm:mx-3 p-2 mt-1.5 md:mt-2.5 mb-2"
						placeholder="Welches Thema suchst du..."
						name="name"				
						type="text"
						value={searchInputValue}
						onChange={handleSearchInputChange}
					/>
					</div>
				<div className="ml-10">
					<button className="btn_One bg-gray-600 text-white ml-0 mt-2 md:mt-4  p-2 rounded-lg"><ImSearch /></button>
			  	</div>
            </form>
			</div>
			<div className="col-sm-1">
					<button className="btn_One bg-gray-600 text-white ml-0 mt-2 md:mt-4  p-2 rounded-lg"><ImCross className=' cursor-pointer scale-75 ' onClick={() => deleteSearchSingleTopic()} /></button>
			</div>               
          
        </div>
      </div>
	  </div>

        <div className=''>

    	{/*     ////////////////////    All Topics    ////////////////////     */}
        <div className="dashboardDataCard forumTitleRowReverse white-glassmorphism-card">
		<div className='row'>
               <div className="mx-2 ml-10 FormStyling text-right text-2xl max-sm:mb-2"><IconWithTooltip2 icon={<button className="buttonTwo btnChange p-2 flex" onClick={()=> setShowCreateTopic(!showCreateTopic)}><FaPencilAlt className='pr-1 mt-1'/>Erstelle Thema</button>} tooltipText="Hier kannst du ein Forum-Thema ertellen." /></div>
              { showCreateTopic && (
				<form onSubmit={createTopic} method="POST"> 
				 <div className='sm:flex ml-3 mb-3'>
					<div className='col-sm-12 sm:flex'>
                    <h4 className="FormStyling mx-5 text-white">Thementitel:</h4>
                    <input className="FormStyling FormStyling2 ml-5 sm:w-full lg:w-[60%] xl:w-[70%]" placeholder={'Thementitel'} 
						name="entityName" onChange={handleTopicNameChange} value={entityName} 
		    			/>
					<div className="mx-2 sm:ml-5 FormStyling sm:text-right text-xl max-sm:pl-32 "><IconWithTooltip2 icon={<button className="buttonTwo btnChange p-2 flex" >Bestätigen</button>} tooltipText="Ertelle das Thema." /></div>
              		</div>
				  </div>
                </form>  
				)} 
		</div>      

	  <div className='row '>
             <div className='col-sm-12'>
	     	<div className='col-sm-3'><h5 className="TextBold mobileView2  max-sm:hidden LinkColor">Erstellt am</h5></div>
             	<div className='col-sm-3'><h5 className="TextBold mobileView2  max-sm:hidden LinkColor">Thema</h5></div>
           
            <div className={`col-sm-2`}>
               <h5 className={`TextBold mobileView2 max-sm:hidden LinkColor`}>Letzte Aktivität</h5>
	    </div>      
	    <div className={`col-sm-2`}><h5 className='TextBold mobileView2  max-sm:hidden LinkColor'>Antworten</h5></div>           
	   <div className={`col-sm-2`}><h5 className='TextBold mobileView2 max-sm:hidden LinkColor'>Profile beteiligt</h5></div>
          </div> 
		  </div>
	</div>	

   <div className=' overflow-y-scroll overflow-x-hidden' style={{minHeight: '435px', maxHeight: '435px'}}>
	{ showSearchRow && getSingleTopicData && (
      <> {getSingleTopicData.createdFromName !== 'Admin' && (
          <div key={getSingleTopicData.topicName} className={` borderOneNew my-1 topicHover hover:text-white rounded-xl white-glassmorphism-card`} onClick={() => handleTopicClick(getSingleTopicData.topicName, '1')}>
            <div className='row '>
             <div className='col-sm-12'>
	     <div className='col-sm-2 max-sm:flex'><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Erstellt am: </h5><h5 className=" mobileView2 "><ShortTimestampFormatter timestamp={getSingleTopicData.topicCreatedAt} /></h5></div>
             <div className='col-sm-4 max-sm:flex'><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Thema:</h5><h5 className=" mobileView2 ">{getSingleTopicData.topicName}</h5></div>
            
            <div className="col-sm-2 max-sm:flex">
			<h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Letzte Aktivität: </h5><h5 className={`mobileView2`}>{getSingleTopicData.answerIds.length > 0?<ShortTimestampFormatter timestamp={getSingleTopicData.answerIds[getSingleTopicData.answerIds.length - 1].answerCreatedAt} />:'0'}</h5>
			</div>      
	    <div className="col-sm-2 max-sm:flex"><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Antworten: </h5><h5 className='mobileView2'>{getSingleTopicData.answerIds.length}</h5></div>           
	    <div className="col-sm-2 max-sm:flex"><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Profile beteiligt: </h5><h5 className='mobileView2'>{getSingleTopicData.userProfilesWhoAnsweredIds.length}</h5></div>
		</div>
		  </div>
        </div>
     )}
   </>
  )}


    {sortedAllTopics && sortedAllTopics.map((topic) => (
      <> {topic.createdFromName !== 'Admin' && (
          <div key={topic.topicName} className={` borderOneNew my-1 topicHover hover:text-white rounded-xl white-glassmorphism-card`} onClick={() => handleTopicClick(topic.topicName, '1')}>
            <div className='row'>
             <div className='col-sm-12'>
	     <div className="relativ p-4 ">
                <>
                  <div className={`opacity-100 hover:opacity-100 absolute p-1 top-2 left-8`}  >
                    {topic.answerIds.length === 0 && (
                      <>
                        <ImCross className=' cursor-pointer scale-75 ' onClick={() => deleteTopic(topic.topicName)} />
                      </>
                    )}
                  </div>
                </>
              </div>
	     <div className='col-sm-2 max-sm:flex'><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Erstellt am: </h5><h5 className=" mobileView2 "><ShortTimestampFormatter timestamp={topic.topicCreatedAt} /></h5></div>
             <div className='col-sm-4 max-sm:flex'><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Thema: </h5><h5 className=" mobileView2 ">{topic.topicName}</h5></div>
            
            <div className={`col-sm-2 max-sm:flex`}>
			<h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Letzte Aktivität: </h5>
			<h5 className={`mobileView2`}>{topic.answerIds.length > 0?<ShortTimestampFormatter timestamp={topic.answerIds[topic.answerIds.length - 1].answerCreatedAt} />:'0'}</h5>
			</div>      
	    <div className={`col-sm-2 max-sm:flex`}><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Antworten: </h5><h5 className='mobileView2'>{topic.answerIds.length}</h5></div>           
	    <div className={`col-sm-2 max-sm:flex`}><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Profile beteiligt: </h5><h5 className='mobileView2'>{topic.userProfilesWhoAnsweredIds.length}</h5></div>
		</div>
		  </div>
        </div>
     )}
   </>
  ))}
</div>
  </div>
 </div>
 </div>
</div>

<div className='col-sm-12 col-lg-4 sm:-mx-5 sm:mr-5' style={{minHeight: '600px', maxHeight: '600px'}}>
<h4 className="TextBold">Meine Themen</h4>
      {/* Second Block - 30% width, 1 row */}
      <div className='px-3 mb-6 forumBorder   overflow-y-scroll overflow-x-hidden'style={{minHeight: '300px', maxHeight: '300px'}}>
        <div className=''>

	{/*     ////////////////////    All Topics from Creator/currentProfile   ////////////////////     */}
        <div className="dashboardDataCard forumTitleRow white-glassmorphism-card max-sm:hidden">
	  <div className='row'>
             <div className='col-sm-12'>
	     	<div className='col-sm-3'><h5 className="TextBold mobileView2 max-sm:hidden LinkColor">Erstellt am</h5></div>
             	<div className='col-sm-6'><h5 className="TextBold mobileView2 max-sm:hidden LinkColor">Thema</h5></div>
           
            <div className={`col-sm-3`}>
               <h5 className={`TextBold mobileView2 max-sm:hidden LinkColor`}>Letzte Aktivität</h5>
	    </div>   
		</div>   
          </div>
	</div>	
    {sortedAllTopicsFromCreator.map((topic) => (
          <div key={topic.topicName} className={` borderOneNew my-1 topicHover rounded-xl white-glassmorphism-card`} onClick={() => handleTopicClick(topic.topicName, '2')}>
            <div className='row'>
             <div className='col-sm-12'>
	     <div className='col-sm-3 max-sm:flex'><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Erstellt am: </h5><h5 className=" mobileView2 "><ShortTimestampFormatter timestamp={topic.topicCreatedAt} /></h5></div>
             <div className='col-sm-6 max-sm:flex'><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Thema: </h5><h5 className=" mobileView2">{topic.topicName}</h5></div>
            
            <div className={`col-sm-3 max-sm:flex`}>
			<h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Letzte Aktivität: </h5>
               <h5 className={`mobileView2`}>{topic.answerIds.length > 0?<ShortTimestampFormatter timestamp={topic.answerIds[topic.answerIds.length - 1].answerCreatedAt} />:'-'}</h5>
	    </div>  
		</div>    
          </div>
          </div>
        ))}
  </div>
 </div>

      {/* Third Block - 30% width, 1 row */}
	  <h4 className="TextBold">Beteiligt in</h4>
      <div className='px-3 mb-6 forumBorder overflow-y-scroll overflow-x-hidden' style={{minHeight: '300px', maxHeight: '300px'}}>
        <div className=''>

	{/*     ////////////////////    All Topics from currentProfile where involved  ////////////////////     */}
        <div className=" dashboardDataCard forumTitleRow white-glassmorphism-card max-sm:hidden">
	  <div className='row'>
             <div className='col-sm-12'>
	     	<div className='col-sm-3 '><h5 className="TextBold mobileView max-sm:hidden LinkColor">Erstellt am</h5></div>
             	<div className='col-sm-6'><h5 className="TextBold mobileView max-sm:hidden LinkColor">Thema</h5></div>
              
            <div className={`col-sm-3`}>
               <h5 className={`TextBold mobileView2 max-sm:hidden LinkColor`}>Letzte Aktivität</h5>
	    </div>
		</div>      
          </div>
	</div>	
    { sortedAllTopicsWhereInvolved && sortedAllTopicsWhereInvolved.map((topic) => (
          <div key={topic.topicName} className={` borderOneNew my-1 topicHover rounded-xl white-glassmorphism-card`} 
			onClick={() => handleTopicClick(topic.topicName, '3')}>
            <div className='row'>
             <div className='col-sm-12'>
	     <div className='col-sm-3 max-sm:flex'><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Erstellt am: </h5><h5 className=" mobileView2 "><ShortTimestampFormatter timestamp={topic.topicCreatedAt}/></h5></div>
             <div className='col-sm-6 max-sm:flex'><h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Thema: </h5><h5 className=" mobileView2 ">{topic.topicName}</h5></div>
          
            <div className={`col-sm-3 max-sm:flex`}>
			<h5 className="TextBold mobileView2 sm:hidden LinkColor mr-2">Letzte Aktivität: </h5>
			<h5 className={`mobileView2`}>{topic.answerIds.length > 0?<ShortTimestampFormatter timestamp={topic.answerIds[topic.answerIds.length - 1].answerCreatedAt} />:'-'}</h5>
			</div>   
		</div>   
          </div>
          </div>
        ))}
      </div>
    </div>
	</div>
 
  </div>
  </div>
</div>
   </>
 )}
 
<div className='container'>
 { getAnswerBoxVisible && (
      <div className={`sm:white-glassmorphism-card rounded-xl`}>
        <div className="col-sm-12">
	 
		<button className="btn_One bg-gray-600 text-white my-4 p-2 rounded-lg" onClick={ () => setAnswerBoxVisible(false)}>zurück</button>
		<h3>{getChosenTopicName}</h3>
		<button className="btn_One bg-gray-600 text-white my-4 p-2 rounded-lg" onClick={ () => setCreateAnswerInTopicIsClicked(true)}>Nachricht erstellen</button>
	
	<div className="justify-between flex">
	<div className="h-full p-4 w-full">
          { topicLists[getChosenTopicType] && topicLists[getChosenTopicType] !== null && topicLists[getChosenTopicType].map((topic) => (
	    <> { ((getChosenTopicType === 4 && topic.createdFromName === 'Admin') || (getChosenTopicType !== 4 && topic.createdFromName !== 'Admin')) && (  
              <div key={topic.topicName}>
                {getChosenTopicName === topic.topicName && (
                  <>

			<>
                            {createAnswerInTopicIsClicked && (
                                <div className="row-span-1 col-span-12 mt-5 relative dashboardDataCard white-glassmorphism-card">
				<div className='row '>
					<div className='col-sm-1 m-2'><ImCross className='text-black-900 cursor-pointer ' onClick={() => setCreateAnswerInTopicIsClicked(false)} /></div>
                               </div>
								<form onSubmit={createAnswer} method="POST" className="w-full">
                                    <div className="flex flex-col sm:flex-row py-3 sm:space-x-2">
                                        {/* Textarea */}
                                        <div className="w-full   sm:w-4/5 flex justify-center items-center">
                                            <textarea
                                                className="w-full FormStyling2 bg-transparent  resize-none text-2xl max-sm:mx-3  sm:mx-auto sm:ml-3"
												data-response-to-answer-id={''}
												data-topic-name={topic.topicName}
                                                value={text}
                                                onChange={(e) => setText(sanitizeInputTextArea(e.target.value))}
                                                onClick={() => setShowEmoji(false)}
                                                rows={4}
                                                placeholder="Deine Nachricht"
												ref={textAreaRef}
                                            ></textarea>
                                        </div>
                                        
                                        {/* Emoji and Send Button */}
                                        <div className="w-full sm:w-2/5 flex flex-col flex-row sm:justify-between sm:items-center mt-2 mx-3 sm:mt-0 space-y-2 sm:space-y-0">
                                        <div className='max-sm:col-sm-12 max-sm:flex'>
                                            <div className='max-sm:col-sm-6 sm:mb-3 max-sm:justify-start'>
                                                <div className=" justify-center max-sm:item-left sm:items-center hover:bg-yellow-400 borderOne rounded-full py-1" onClick={() => setShowEmoji(!showEmoji)}>
                                                    <p className="cursor-pointer sm:px-44 max-sm:px-20 scale-150 pt-2"><ImHappy className=''/></p>
                                                </div>
                                            </div>
                                            {showEmoji && (
                                                <>
                                                    <div className="absolute top-[-170%] left-80 z-10 max-sm:hidden">
                                                        <Picker data={data} emojiSize={15} emojiButtonSize={22} onEmojiSelect={addEmoji} maxFrequentRows={1} perLine={20} skinTonePosition="search" previewPosition="none" />
                                                    </div>
                                                    <div className="absolute max-sm:top-[-200%] right-2 z-40 sm:hidden">
                                                        <Picker data={data} emojiSize={18} emojiButtonSize={22} onEmojiSelect={addEmoji} maxFrequentRows={1} perLine={7} skinTonePosition="search" previewPosition="none" />
                                                    </div>
                                                </>
                                            )}	
                                            <div className='max-sm:col-sm-6 max-sm:w-full max-sm:mr-10'>
                                                <button className="btn_One w-full sm:w-auto  max-sm:py-2 max-sm:mx-5 max-sm:mt-1 text-center sm:px-36 sm:py-3 rounded-md titleBackgroundColor text-gray-100">
                                                    Senden
                                                </button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </form>
                                </div>
                                )}
    
                              </>





                    <div className=''>
                      {topic.answerIds.length > 0 && (
                        <div className=''>
                        {[...topic.answerIds].reverse().map((answer, index) => (  
                            <div key={answer.id + index}>
 
            		<div className='row  dashboardDataCard white-glassmorphism-card'>
                		<div className='col-sm-12 sm:flex'>
					{ answer.answerSenderName === localStorage.getItem('profileName') && (
						<div className='mr-5'><ImCross className='text-black-900 cursor-pointer mt-4 ' onClick={() => deleteAnswer(answer.randomIdNumber, null)} /></div>
	     				)}
	     				<div className='col-sm-3 sm:flex'><h5 className='mobileView2 mr-2 pt-1'>Erstellt am:</h5><h4 className="TextBold mobileView LinkColor"><ShortTimestampFormatter timestamp={answer.answerCreatedAt}/></h4></div>
             				<div className='col-sm-4 sm:flex'><h5 className='mobileView2 mr-2 pt-1'>Profilname:</h5><h4 className="TextBold mobileView LinkColor">{answer.answerSenderName}</h4></div>
     	    				<div className={`col-sm-2`}><h5 className='mobileView2 pt-1'>Antworten: {answer.answerIds.length}</h5></div>
					<div className={`col-sm-1`}><h5 className='mobileView2'><button className='' 
						onClick={()=> updateHelpfulCount(answer.randomIdNumber, answer.answerSenderName)}><MdThumbUpAlt className='mr-2 mt-1' /></button>{answer.helpfulCount}</h5>
					</div>
					<div className={`col-sm-2 justify-end mt-3`} ><button className="btn_One w-full p-1 text-center rounded-md titleBackgroundColor text-gray-100"
						onClick={()=>  setCreateAnswerisClicked({ isClicked: true, timestamp: answer.id.timestamp }) }>Antworten</button>
					</div>
				</div>
				<hr style={{ borderColor: 'lightgray' }} className={`horizontalLine mt-5 mb-5 mx-2`}/>
                
	   				<div className={`col-sm-12`}><h4 className='mobileView2 mobileView3'>{answer.answerText}</h4></div>
					<div className={`col-sm-12 mb-2`} onClick={()=> answerIsCLicked(answer.id.timestamp)} >
						{getAnswerIsClickedId === answer.id.timestamp ? (<CgChevronDown className='scale-150' />) : (<CgChevronRight className='scale-150'/>)}
					</div>
            		
            


                         <>
                            {createAnswerisClicked.isClicked && createAnswerisClicked.timestamp === answer.id.timestamp && (
                                <div className="row-span-1 col-span-12 mt-5 relative">
				<div className='row'>
					<div className='col-sm-1 mt-2 ml-3'><ImCross className='text-black-900 cursor-pointer ' onClick={() => setCreateAnswerisClicked({ isClicked: false, timestamp: null }) } /></div>
                               </div>
								<form onSubmit={createAnswer} method="POST" className="w-full mb-10 ml-10">
                                    <div className="flex flex-col sm:flex-row py-3 sm:space-x-2">
                                        {/* Textarea */}
                                        <div className="w-full sm:w-4/5 flex max-sm:w-[80%] justify-center items-center">
                                            <textarea
                                                className="w-full bg-transparent FormStyling2 resize-none text-2xl max-sm:mx-3  sm:mx-auto sm:ml-3"
												data-response-to-answer-id={answer.randomIdNumber}
												data-topic-name={topic.topicName}
                                                value={text}
                                                onChange={(e) => setText(sanitizeInputTextArea(e.target.value))}
                                                onClick={() => setShowEmojiTwo(false)}
                                                rows={4}
                                                placeholder="Deine Nachricht"
												ref={textAreaRef}
                                            ></textarea>
                                        </div>
                                        
                                        {/* Emoji and Send Button */}
                                        <div className="w-full sm:w-2/5 flex flex-col flex-row sm:justify-between sm:items-center mt-2 mx-3 sm:mt-0 space-y-2 sm:space-y-0">
                                        <div className='max-sm:col-sm-12 max-sm:flex'>
                                            <div className='max-sm:col-sm-6 sm:mb-3 max-sm:justify-start'>
                                                <div className=" justify-center max-sm:item-left sm:items-center hover:bg-yellow-400 borderOne rounded-full py-1" onClick={() => setShowEmojiTwo(!showEmojiTwo)}>
                                                    <p className="cursor-pointer sm:px-44 max-sm:px-20 scale-150 pt-2"><ImHappy className=''/></p>
                                                </div>
                                            </div>
                                            {showEmojiTwo && (
                                                <>
                                                    <div className="absolute top-[-150%] left-80 z-10 max-sm:hidden">
                                                        <Picker data={data} emojiSize={18} emojiButtonSize={23} onEmojiSelect={addEmoji} maxFrequentRows={1} perLine={20} skinTonePosition="search" previewPosition="none" />
                                                    </div>
                                                    <div className="absolute max-sm:top-[-210%] right-2 z-10 sm:hidden">
                                                        <Picker data={data} emojiSize={18} emojiButtonSize={22} onEmojiSelect={addEmoji} maxFrequentRows={1} perLine={7} skinTonePosition="search" previewPosition="none" />
                                                    </div>
                                                </>
                                            )}	
                                            <div className='max-sm:col-sm-6 max-sm:w-full max-sm:mr-10 max-sm:px-10'>
                                                <button className="btn_One w-full   max-sm:py-2 max-sm:mx-2 max-sm:px-0 max-sm:mt-1 text-center sm:px-36 sm:py-3 rounded-md titleBackgroundColor text-gray-100">
                                                    Senden
                                                </button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </form>
                                </div>
                                )}
    
                              </>


          		{ getAnswerIsClickedId === answer.id.timestamp && answer.answerIds.length > 0 && [...answer.answerIds].reverse().map((innerAnswer, index) => (
            		<div key={innerAnswer.id}>
               			<div className='row'>
				    <div className='col-sm-12'>
					<div className='col-sm-2'></div>
                		<div className='col-sm-10 '>
							  <div className='col-sm-12 forumBorder bg-gray-100 my-2'>
								<div className='col-sm-12  my-2'>

									{ innerAnswer.answerSenderName === localStorage.getItem('profileName') && (
										<div className='col-sm-1'>
											<ImCross className='text-black-900 cursor-pointer ' onClick={() => deleteAnswer(innerAnswer.randomIdNumber, answer.randomIdNumber )} />
										</div>
										)}
									<div className='col-sm-4 sm:flex'><h5 className='mobileView2 mr-2 pt-1'>Erstellt am:</h5><h4 className="TextBold mobileView LinkColor"><ShortTimestampFormatter timestamp={innerAnswer.answerCreatedAt}/></h4></div>
											<div className='col-sm-5 sm:flex'><h5 className='mobileView2 mr-2 pt-1'>Profilname:</h5><h4 className="TextBold mobileView LinkColor">{innerAnswer.answerSenderName}</h4></div>
									<div className={`col-sm-2 `}><h5 className='mobileView2'><button className='' 
										onClick={()=> updateHelpfulCount(innerAnswer.randomIdNumber,  innerAnswer.answerSenderName)}><MdThumbUpAlt className='mr-2 mt-1' /></button>{innerAnswer.helpfulCount}</h5>
									</div>
					</div>
	   				<div className={`col-sm-12  `}>
					   <hr style={{ borderColor: 'lightgray' }} className={`horizontalLine mb-5 mx-2`}/>
					
						<h4 className='mobileView2 mobileView3'>{innerAnswer.answerText}</h4></div>
            			   </div>
						   </div></div>
				</div>                  
           		</div>
			))}

		 </div>
		 </div>
               ))}
	    </div> 
                        
                          )}
                        </div>
                        </>
                      )}
                    </div>
	           )}
                  </> 
                ))}
              </div>
          </div>		  
        </div>
	</div>
      )}
		</div>
							

	</div>
  </div>
 


	)
																
															

}
export default ForumComp; 
export { default as ForumComp } from './ForumComp.js';