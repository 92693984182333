import './BookCreationView.css';
import api from '../../api/axiosConfig';
import { useEffect, useState , useCallback} from 'react';
import { Link } from "react-router-dom";

import logo from '../../images/shiny_symbol_withcircle1.png';

import { useNavigate } from "react-router-dom";

import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';

//Security
import { SanitizeInput } from '../../tools/security/SanitizeInput';
import { sanitizeInputTextArea } from '../../tools/security/SanitizeInputTextArea';
import { sanitizeInputTextAreaBig } from '../../tools/security/SanitizeInputTextAreaBig';
import { exeedsMaxCharacter } from '../../tools/security/exeedsMaxCharacter';
import { exeedsMaxCharacterTextArea } from '../../tools/security/exeedsMaxCharacterTextArea';
import { exeedsMaxCharacterTextAreaBig } from '../../tools/security/exeedsMaxCharacterTextAreaBig';

import ReactQuill from "react-quill";   
import 'react-quill/dist/quill.snow.css';

import he from 'he'; // libraries like he or sanitize-html-react to escape special characters.
import DOMPurify from 'dompurify';

 function DisplayHtml({ htmlContent }) {   
    //const escapedHtml = he.escape(htmlContent);  only html visible and not in formated way visible!
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    return (
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    );
} 

/*
function trimFileName ({fileName}) {

    const index = fileName.indexOf('.');
    const indexNew = index -1 
    const trimmedFileName = fileName.substring(0, indexNew);
    return (<p>{trimmedFileName}</p>);
  };  */


const BookCreationView = ({baseUrl}) => {


    const [prevFileName, setPrevFileName,  ] = useState('');
    const [prevBookFileName, setPrevBookFileName,  ] = useState('');
    const [getBookFileName, setBookFileName,  ] = useState('');
    const [getFileName, setFileName,  ] = useState('');

    const [prevBookSampleFileName, setPrevBookSampleFileName,  ] = useState('');
    const [getBookSampleFileName, setBookSampleFileName,  ] = useState('');

    const [imagePreview, setImagePreview] = useState('');

    const [getBelongName, setBelongName,  ] = useState([]);
    const [render, setRender] = useState(false);  

    const [imageSrc, setImageSrc] = useState(null);

    const [entityJacketText, setEntityJacketText] = useState('');
    const [entityDescr, setEntityDescr] = useState('');
    const [entityName, setEntityName] = useState('');
    const [entityPages, setEntityPages] = useState('');
    const [entitySubTitle, setEntitySubTitle] = useState('');
    const [entityTable, setEntityTable] = useState('');
    const [entityTargetReader, setEntityTargetReader] = useState('');
    const [entitySingleOrTril, setEntitySingleOrTril] = useState('');
    
    const [getFileSizeToBig, setFileSizeToBig] = useState(false);
    const [getFileTypeWrong, setFileTypeWrong] = useState(false);
    
    const [getCharacterCountJack, setCharacterCountJack] = useState(0);
    const [getCharacterCountTable, setCharacterCountTable] = useState(0);
    const [getCharacterCountDescr, setCharacterCountDescr] = useState(0);

    const [getBookUploadSuccess, setBookUploadSuccess] = useState(false);
    const [getBookSampleUploadSuccess, setBookSampleUploadSuccess] = useState(false);
    const [getBookCreationSuccess, setBookCreationSuccess] = useState(false);
    
    const [showGenreChange, setGenreChange] = useState(false);

    const [error, setError] = useState(null);


    	const modules = {
        toolbar: [
          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'], 
          ['clean']
      ],
        		clipboard: {
            		// toggle to add extra line breaks when pasting HTML:
            		matchVisual: false,
        }
      }

      
    const handleQuillTableOfContentChange = useCallback((value) => {
      try {
        const characterCount = value.replace(/<[^>]*>/g, '').length; // Strip HTML tags and count characters
        setCharacterCountTable(characterCount);
        if(characterCount <= 500){ 
          setEntityTable(value);
        } 
        
      } catch (error) {
        handleError('Error handling quill exp change:', error);
      }
    }, []);

    const handleQuillJacketTextChange = useCallback((value) => {
      try {
        const characterCount = value.replace(/<[^>]*>/g, '').length; // Strip HTML tags and count characters
        setCharacterCountJack(characterCount);
        if(characterCount <= 1000){ 
          setEntityJacketText(value);
        } 
        
      } catch (error) {
        handleError('Error handling quill jacketText change:', error);
      }
    }, []);

    const handleQuillDescrChange = useCallback((value) => {
      try {
        const characterCount = value.replace(/<[^>]*>/g, '').length; // Strip HTML tags and count characters
        setCharacterCountDescr(characterCount);
        if(characterCount <= 3000){ 
          setEntityDescr(value);
        } 
        
      } catch (error) {
        handleError('Error handling quill descr change:', error);
      }
    }, []);
    
    
    const handleTitleChange = (e) => {
      try {
        const inputValue = exeedsMaxCharacter(e.target.value);
        setEntityName(inputValue.trim());
        setBelongName(inputValue.trim());
      } catch (error) {
        handleError('Error handling name change:', error);
      }
    };
    
    const handlePagesChange = (e) => {
      try {
        const value = e.target.value
        const regex = /^\d*$/; 

        if (regex.test(value) && (value === '' || parseInt(value) <= 10000)) {
          const inputValue = exeedsMaxCharacter(value);
          setEntityPages(inputValue);
        }
        
      } catch (error) {
        handleError('Error handling age change:', error);
      }
    };
    
    const handleSubTitleChange = (e) => {
      try {
        const inputValue = exeedsMaxCharacter(e.target.value);
        setEntitySubTitle(inputValue);
      } catch (error) {
        handleError('Error handling website change:', error);
      }
    };

    const handleTargetReaderChange = (e) => {
      try {
        const inputValue = exeedsMaxCharacterTextAreaBig(e.target.value);
        setEntityTargetReader(inputValue);
      } catch (error) {
        handleError('Error handling name change:', error);
      }
    };

    const handleSingleOrTrilChange = (e) => {
      try {
        const inputValue = exeedsMaxCharacterTextAreaBig(e.target.value);
        setEntitySingleOrTril(inputValue);
      } catch (error) {
        handleError('Error handling name change:', error);
      }
    };
    
   const entityHandleSubmit = async (e) => {
      try {
        e.preventDefault();
  
    
        const entityNameVar = SanitizeInput(entityName);
        const pagesVar = SanitizeInput(entityPages);
        const descriptionVar = DOMPurify.sanitize(entityDescr);
        const entitySubTitleVar = SanitizeInput(entitySubTitle);
        const jacketTextVar = DOMPurify.sanitize(entityJacketText);
        const tableOfContentVar = DOMPurify.sanitize(entityTable);
        const targetReaderVar = sanitizeInputTextArea(entityTargetReader);
        const singleOrTrilVar = sanitizeInputTextArea(entitySingleOrTril);
    
        const imgNameVar = SanitizeInput(getFileName);
        const bookFileNameVar = SanitizeInput(getBookFileName);

        const addNewEntityData = { 
          authorName: localStorage.getItem('profileName'),
          ...(bookFileNameVar !== '' && { bookFileName: bookFileNameVar }), 
          title: entityNameVar,
          subTitle: entitySubTitleVar,
          pages: pagesVar,
          targetReader: targetReaderVar,
          singleOrTril: singleOrTrilVar,
          jacketText: jacketTextVar,
          tableOfContent: tableOfContentVar,
          description: descriptionVar,
          ...(imgNameVar !== '' && { coverImgName: imgNameVar }),
          
        };

        const newBookProfileData2 = {
          userName: '',
          userProfileName: entityNameVar, //getBelongName,
          userProfileType: "Book",
        };
    
        const response = await api.post("/api/bookProjects", addNewEntityData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
        });
        if(response.data !== null && !response.data.isEmpty ){
        const response2 = await api.post(`/api/userProfile`, newBookProfileData2, {
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
        });
        if(response2.data !== null && !response2.data.isEmpty ){
          setBookCreationSuccess(true);
        }
      }

      if (response && response.data) {

        const addNewGenreData = {
          ...checkedGenreOptions // Include all genre options
        };

        const profileType = "Book";

          const response5 = await api.put(`/api/genres/update/${entityNameVar}&${profileType}`, addNewGenreData);
          if(response5.data != null){

          }else {
          handleError("Updating / Creating Genres ...");
        }
     }

      } catch (error) {
        handleError('Error submitting Entity data:', error);
      }
    };


    const rerender = () => {
      setRender(true);
    };
    
 
    const handleImgFileChange = (e) => {
      try {
        setPrevFileName(getFileName);
        const filename = e.target.files[0].name;
        setFileName(filename);     
  
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
  
        setRender(true);
      } catch (error) {
        handleError('Error handling file change:', error);
      }
    };

    
    const handleImgFileUpload = async (e) => {
      try {
        e.preventDefault();
        const fileInput = e.target.querySelector('input[type="file"]');
        const file = fileInput.files[0];
    
        if (!file) {
          handleError('No file selected');
          return;
        }
    
        const allowedFileTypes = ['image/jpeg', 'image/png'];
        if (!allowedFileTypes.includes(file.type)) {
          setFileTypeWrong(true);
          handleError('Invalid file type');
          return;
        }
    
        const maxSize = 5 * 1024 * 1024; // 5 MB
        if (file.size > maxSize) {
          setFileSizeToBig(true);
          handleError('File size exceeds the limit of 5 MB');
          return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('uploadName', localStorage.getItem('profileName'));
        formData.append('belongName', getBelongName);
    
        const response = await api.post('/api/files/uploadCoverImg', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    
      setTimeout(() => {
        // Update the state to trigger a re-render
        fetchImage();
      }, 1000); // Delay in milliseconds (adjust as needed)
  
      if(prevFileName !== null && prevFileName !== ''){
          const response2 = await api.delete(`/api/files/delete/${prevFileName}`, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
       }

      } catch (error) {
        handleError('Error uploading file:', error);
      }
    };
    
    const fetchImage = async () => {
      try {
        const newImageSrc = await api.get(`/api/files/image/${getFileName}`);
        setImageSrc(`${baseUrl}/api/files/image/${getFileName}`);
      } catch (error) {
        handleError('Error fetching image:', error);
      }
    };

    const handleBookFileChange = (e) => {
      try {
        setPrevBookFileName(getFileName);
        const filename = e.target.files[0].name;
        setBookFileName(filename);     
        setBookUploadSuccess(false);
       /* const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]); */
  
        //setRenderBookFile(!renderBookFile);
      } catch (error) {
        handleError('Error handling file change:', error);
      }
    };

    const handleBookSampleFileChange = (e) => {
      try {
        setPrevBookSampleFileName(getBookSampleFileName);
        const filename = e.target.files[0].name;
        setBookSampleFileName(filename);     
        setBookSampleUploadSuccess(false);
      } catch (error) {
        handleError('Error handling file change:', error);
      }
    };

    const handleReadingSampleFileUpload = async (e) => {
      try {
        e.preventDefault();
        const fileInput = e.target.querySelector('input[type="file"]');
        const file = fileInput.files[0];
    
        if (!file) {
          handleError('No file selected');
          return;
        }
    
        const allowedFileTypes = ['application/pdf',
        'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (!allowedFileTypes.includes(file.type)) {
          setFileTypeWrong(true);
          handleError('Invalid file type');
          return;
        }
    
        const maxSize = 5 * 1024 * 1024; // 5 MB
        if (file.size > maxSize) {
          setFileSizeToBig(true);
          handleError('File size exceeds the limit of 5 MB');
          return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('uploadName', 'ReadingSample');
        formData.append('belongName', getBelongName);
    
        const response = await api.post('/api/files/uploadBook', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    
      setTimeout(() => {
        // Update the state to trigger a re-render
        fetchBookSampleFileFunc();
      }, 1000); // Delay in milliseconds (adjust as needed)
  
      if(prevBookSampleFileName !== null && prevBookSampleFileName !== '' && !prevBookSampleFileName.isEmpty){
          const response2 = await api.delete(`/api/files/delete/${prevBookSampleFileName}`, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
       }

      } catch (error) {
        handleError('Error uploading file:', error);
      }
    };


    const handleBookFileUpload = async (e) => {
      try {
        e.preventDefault();
        const fileInput = e.target.querySelector('input[type="file"]');
        const file = fileInput.files[0];
    
        if (!file) {
          handleError('No file selected');
          return;
        }
    
        const allowedFileTypes = ['application/pdf',
        'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (!allowedFileTypes.includes(file.type)) {
          setFileTypeWrong(true);
          handleError('Invalid file type');
          return;
        }
    
        const maxSize = 5 * 1024 * 1024; // 5 MB
        if (file.size > maxSize) {
          setFileSizeToBig(true);
          handleError('File size exceeds the limit of 5 MB');
          return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('uploadName', 'FullBook');
        formData.append('belongName', getBelongName);
    
        const response = await api.post('/api/files/uploadBook', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    
      setTimeout(() => {
        // Update the state to trigger a re-render
        fetchBookFile();
      }, 1000); // Delay in milliseconds (adjust as needed)
  
      if(prevBookFileName !== null && prevBookFileName !== '' && !prevBookFileName.isEmpty){
          const response2 = await api.delete(`/api/files/delete/${prevBookFileName}`, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
       }

      } catch (error) {
        handleError('Error uploading file:', error);
      }
    };

    const fetchBookFile = async () => {
      try {
        const newBookFileData = await api.get(`/api/files/${getBookFileName}`);
        //console.log('newBookFileData: ', newBookFileData);
        //console.log('newBookFileData.fileName: ', newBookFileData.fileName);
        if(newBookFileData !== null && !newBookFileData.isEmpty){
            setBookUploadSuccess(true);
            //console.log('newBookFileData.fileName: ', newBookFileData.data.fileName);
        }
      } catch (error) {
        handleError('Error fetching image:', error);
      }
    };

    const fetchBookSampleFileFunc = async () => {
      try {
        const newBookFileData = await api.get(`/api/files/${getBookSampleFileName}`);
        //console.log('newBookSampleFileData: ', newBookFileData);
        //console.log('newBookSampleFileData.fileName: ', newBookFileData.fileName);
        if(newBookFileData !== null && !newBookFileData.isEmpty){
            setBookSampleUploadSuccess(true);
            //console.log('newBookSampleFileData.fileName: ', newBookFileData.data.fileName);
        }
      } catch (error) {
        handleError('Error fetching image:', error);
      }
    };

    const [checkedGenreOptions, setCheckedGenreOptions] = useState({
      fiction: false, nonFiction: false, mystery: false, thriller: false, romance: false,
      fantasy: false, scienceFiction: false, horror: false, historical: false, youngAdult: false, newAdult: false,
       children: false, adventure: false, biography: false, selfHelp: false, poetry: false, drama: false,
      crime: false, graphicNovels: false, shortStories: false, dystopian: false, paranormal: false, memoir: false,
       cookbooks: false, spirituality: false, travel: false, science: false, history: false
    });
    
    const handleGenreCheckboxChange = (option) => {
      setCheckedGenreOptions((prevOptions) => ({
        ...prevOptions,
        [option]: !prevOptions[option],
      }));
    };
    

      useEffect(() => {
          setFileName(" ");
          setBookCreationSuccess(false);
          setGenreChange(false);
      }, []);

      useEffect(() => {
        if (render) {
        // Fetch and update the image source after a successful upload
        fetchImage();
        setRender(false);      
        }
      }, [render]); 
                  

    const navigate2 = useNavigate();

    useEffect(() => {
      LogoutRedirectCheck(navigate2);
    }, [render, getBookCreationSuccess, entityDescr, entityName, entityPages, prevFileName, prevBookFileName]);
              

    const handleError = (message, error) => {
      console.error(message, error);
      setError('An error occurred. Please try again.');
      };


  return (
    <div className="BookCreationView mb-28">
      <div className="row w-full">

      {getBookCreationSuccess && (
      <div>
                <div>
          <div className='blurPage z-20 fixed  inset-0 h-screen w-screen overflow-hidden'>
          </div>
          <div className='newsBox flex flex-col items-center justify-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30' style={{ minWidth: '450px', maxWidth:'450px'}}>
              <div className="w-full text-center">
                  <h3 className="text-center">ERFOLG</h3>
                  <div className="row">                                       
                      <div className=" text-center"><h4 className="text-center">Du hast erfolgreich ein Buch erstellt</h4></div>      
                  </div>
                  <div className="row FormStyling mx-10 px-10">
                      <div className="text-center" style={{ minWidth: '100px', minHeight: '70px' }}>
                          <h4 className="text-center">Bitte schließe die Seite<br></br>oder wechsel diese.</h4>
                      </div>
                  </div> 
              </div>   
          </div>
       </div>


      </div> 
      )}

      {!getBookCreationSuccess && (
        <><div className="row row-col-12 text-center m-4">
            {/*  {error && <div style={{ color: 'red' }}>{error}</div>}   
            {getFileSizeToBig && (<div><p style={{ color: 'red' }}>Deine Datei überschreitet 5 MB an Größe!</p></div>)}
            {getFileTypeWrong && (<div><p style={{ color: 'red' }}>Deine Datei muss vom Typ PNG oder JPEG sein!</p></div>)}
          */}
          </div></>
          )}
          <div className="container ">
      
              <div className="row  ">
      
                <><div className=''>

                  <form onSubmit={entityHandleSubmit} method="POST">

                    <div className="row profileDataCard paddingOne">
                      <div className="row max-md:flex titleRowBackgroundcolor white-glassmorphism-card ">
                        <div className="col col-sm-4"><h2 className="FormStyling text-left titleTextColor">Stammdaten</h2></div>
                        <div className="col col-sm-8 FormStyling text-right justify-end w-full">
                          <button className="px-48 max-md:px-2 max-md:ml-8 py-2 bg-gray-600 text-white rounded mr-2">Buchprojekt erstellen</button>
                        </div>
                      </div>
                      <hr className='horizontalLine' />
                      <div className="row mainRowBackgroundcolor white-glassmorphism-card pt-3">
                        <div className="col col-sm-2 max-sm:col-sm-3 text-left" style={{ minWidth: '100px', minHeight: '180px' }}>
                          <div>
                            <h3 className="FormStyling">Titel</h3>
                            <input className="FormStyling FormStyling2 w-full" name="entityName" onChange={handleTitleChange} value={entityName} />
                            <br></br>
                            <h3 className="FormStyling">Normseiten</h3>
                            <input className="FormStyling FormStyling2 w-full" name="entityPages" onChange={handlePagesChange} value={entityPages} />
                          </div>
                        </div>
                        <div className="col col-sm-3 text-left" style={{ minWidth: '100px', minHeight: '180px' }}>
                          <div>
                            <h3 className="FormStyling">Untertitel</h3>
                            <input className="FormStyling FormStyling2 w-full" name="entitySubTitle" onChange={handleSubTitleChange} value={entitySubTitle} />
                            <br></br>
                            <h3 className="FormStyling">Genre</h3>
                            <div className='buttonTwo btnChange text-center mx-4 mt-5 cursor-pointer' onClick={()=> setGenreChange(!showGenreChange)}>
                            Genres Bearbeiten
                          </div>
                        </div>
                        </div>
                        <div className="col col-sm-7 text-left" style={{ minWidth: '100px', minHeight: '180px' }}>
                          <div>
                            <h3 className="FormStyling">Lesezielgruppe</h3>
                            <input className="FormStyling FormStyling2 w-full" name="entityTargetReader" onChange={handleTargetReaderChange} value={entityTargetReader} />
                            <h3 className="FormStyling">Einzelband / Teil einer Reihe</h3>
                            <input className="FormStyling FormStyling2 w-full" name="entitySingleOrTril" onChange={handleSingleOrTrilChange} value={entitySingleOrTril} />                    
                        </div>
                        </div>
                        <div className="col col-sm-12 max-sm:col-sm-12 text-left">
                          <div className="row textAreaOne">
                            <h3 className="FormStyling">Klappentext</h3>
                            <div className="row textAreaOne pt-5" style={{ width: '100%' }}>
                            <ReactQuill
                                theme='snow'
                                formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link']}
                                placeholder="Deine Klappentext..."
                                modules={modules}
                                onChange={handleQuillJacketTextChange}
                                value={entityJacketText}
                            />
                                    <div className='pt-6 pb-3'
                                      style={{
                                        position: 'relative',
                                        top: '0px',
                                        right: '-10px', // Adjust the distance from the right as needed
                                        color: 'gray',
                                      }}
                                      >
                                  {getCharacterCountJack} / 1000
                                </div>  

			                      {/*}	<div><DisplayHtml htmlContent={contentExpClean} /></div> */}

                            </div>
                          </div>
                        </div>
                        </div>
                        <div className="row mainRowBackgroundcolor white-glassmorphism-card pt-3">
                        { showGenreChange && (
                        <div className="flex flex-wrap gap-4">
                            {Object.entries(checkedGenreOptions).map(([genre, isChecked]) => (
                              <div key={genre} className="flex items-center mb-4 mx-3 text-3xl text-[#8b90a5] hover:text-[#babbcf]">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => handleGenreCheckboxChange(genre)}
                                  id={genre}
                                  className="cursor-pointer rounded items-center  mb-6 border-gray-300"
                                />
                                <label htmlFor={genre} className="capitalize mt-3 ml-2">{genre}</label>
                              </div>
                            ))}
                          </div>
                          )}
                      </div>
                    </div>

                    <div className="row profileDataCard paddingOne">
                      <div className="row titleRowBackgroundcolor  white-glassmorphism-card ">
                        <div className='col col-sm-4'><h2 className="FormStyling text-left titleTextColor">Inhaltsverzeichnis</h2></div>
                      </div>
                      <hr className='horizontalLine' />
                      <div className="row mainRowBackgroundcolor">
                        <div className="col col-sm-12 text-left mainRowBackgroundcolor white-glassmorphism-card ">
                          <div className="row textAreaOne pt-5" style={{ width: '100%' }}>

                          <ReactQuill
                                theme='snow'
                                formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link']}
                                placeholder="Deine Inhalt..."
                                modules={modules}
                                onChange={handleQuillTableOfContentChange}
                                value={entityTable}
                            />
                                    <div className='pt-6 pb-3'
                                      style={{
                                        position: 'relative',
                                        top: '0px',
                                        right: '-10px', // Adjust the distance from the right as needed
                                        color: 'gray',
                                      }}
                                      >
                                  {getCharacterCountTable} / 500
                                </div>  

			                      {/*}	<div><DisplayHtml htmlContent={contentExpClean} /></div> */}

                            </div>
                          </div>
                        </div>
                      </div>
                    

                    <div className="row profileDataCard paddingOne">
                      <div className="row titleRowBackgroundcolor  white-glassmorphism-card ">
                        <div className='col col-sm-4'><h2 className="FormStyling text-left titleTextColor">Genauere Beschreibung</h2></div>
                      </div>
                      <hr className='horizontalLine' />
                      <div className="row mainRowBackgroundcolor">
                        <div className="col col-sm-12 text-left mainRowBackgroundcolor white-glassmorphism-card ">
                          <div className="row textAreaOne pt-5" style={{ width: '100%' }}>

                                <ReactQuill
                                    theme='snow'
                                    formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video']}
                                    placeholder="Die genauere Beschreibung..."
                                    modules={modules}
                                    onChange={handleQuillDescrChange}
                                    value={entityDescr}
                                />
                                    <div className='pt-6 pb-3'
                                      style={{
                                        position: 'relative',
                                        top: '0px',
                                        right: '-10px', // Adjust the distance from the right as needed
                                        color: 'gray',
                                      }}
                                      >
                                      {getCharacterCountDescr} / 3000
                                    </div>  

                                {/*}	<div><DisplayHtml htmlContent={contentExpClean} /></div> */}

                                </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                  <div className="row profileDataCard paddingOne">
                    <div className="row titleRowBackgroundcolor  white-glassmorphism-card ">
                      <div className='col col-sm-4'><h2 className="FormStyling text-left titleTextColor">Coverdesign & Coverbild</h2></div>
                    </div>
                    <hr className='horizontalLine' />
                    <div className="row mainRowBackgroundcolor text-center justify-center items-center">
                      <div className="col col-sm-12 mainRowBackgroundcolor white-glassmorphism-card ">
                      {getFileSizeToBig && (<div><p style={{ color: 'red' }}>Deine Datei  überschreitet 5 MB an Größe!</p></div>)}
                      {getFileTypeWrong && (<div><p style={{ color: 'red' }}>Deine Datei muss vom Typ PNG oder JPEG sein!</p></div>)}


                        <div className="col col-sm-4 flex text-center justify-center items-center">
                          <div className="">
                            <div>
                              <div className="text-left ml-2.5 my-10 max-sm:my-0" style={{ minWidth: '100px', minHeight: '240px' }}>
                                <div><h3 className='text-center'>Vorschau</h3></div>
                                <div className="" style={{ minWidth: '100px', minHeight: '240px' }}>
                                  <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }}>
                                    <div>
                                      {imagePreview && (
                                        <img src={imagePreview} alt="Preview" style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }} />
                                      )}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col col-sm-4 text-center justify-center mt-48 max-md:mt-12">
                          <div className="textAreaOne ">
                            <form className='' onSubmit={handleImgFileUpload} method="POST" encType="multipart/form-data">
                              <input className='w-full text-center justify-center items-center' type="file" onChange={handleImgFileChange} />
                              <br></br>
                              <button className="buttonOne px-4 py-2 bg-gray-600 text-white rounded mr-2">hochladen</button>
                            </form>
                          </div>
                        </div>
                        <div className="col col-sm-4 flex text-center justify-center items-center">
                          <div className="">
                            <div>
                              <div className="my-10" style={{ minWidth: '100px', minHeight: '240px' }}>
                                <div><h3 className='text-center'>Hochgeladen</h3></div>
                                <div className=" " style={{ minWidth: '100px', minHeight: '240px' }}>
                                  <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }}>
                                    <div>
                                      {imageSrc && (
                                        <img className="" src={imageSrc} alt="Uploaded" style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }} />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row profileDataCard paddingOne">
                    <div className="row titleRowBackgroundcolor  white-glassmorphism-card ">
                      <div className='col col-sm-6'><h2 className="FormStyling text-left titleTextColor">Buchdatei und Leseprobe</h2></div>
                    </div>
                    <hr className='horizontalLine' />
                    <div className="row mainRowBackgroundcolor text-center justify-center items-center">
                      <div className="col col-sm-12 titleRowBackgroundcolor white-glassmorphism-card ">

                        <div className="col col-sm-4 text-center justify-center mt-16 mb-10 max-md:mt-12">
                          <div className="textAreaOne ">
                          <h4 className='font-bold mb-10'>Leseprobe</h4>
                            <form className='' onSubmit={handleReadingSampleFileUpload} method="POST" encType="multipart/form-data">
                              <input className='w-full text-center justify-center items-center' type="file" onChange={handleBookSampleFileChange} />
                              <br></br>
                              <button className="buttonOne px-4 py-2 bg-gray-600 text-white rounded mr-2">hochladen</button>
                            </form>
                          </div>
                        </div>
                        <div className="col col-sm-8 flex text-center justify-center items-center">
                        { getBookSampleUploadSuccess && ( 
                        <div className="">
                            <div>
                              <div className="my-10" style={{ minWidth: '300px', minHeight: '50px' }}>
                                <div><h3 className='text-center'>Erfolgreich Hochgeladen</h3></div>
                                <div className=" " style={{ minWidth: '300px', minHeight: '60px' }}>
                                  <div className="profileImg2 bg-blue-200" style={{ maxWidth: '240px', maxHeight: '50px', minWidth: '300px', minHeight: '50px' }}>
                                    <div className='p-5 text-center'>
                                       <p><b>100%</b></p> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <hr style={{ borderColor: 'lightgray' }} className='white-glassmorphism-card horizontalLine'/>
                
                    <div className="row mainRowBackgroundcolor text-center justify-center items-center">
                      <div className="col col-sm-12 mainRowBackgroundcolor white-glassmorphism-card ">

                        <div className="col col-sm-4 text-center justify-center mt-16 mb-10 max-md:mt-12">
                          <div className="textAreaOne ">
                            <h4 className='font-bold mb-10'>Buchdatei</h4>
                            <form className='' onSubmit={handleBookFileUpload} method="POST" encType="multipart/form-data">
                              <input className='w-full text-center justify-center items-center' type="file" onChange={handleBookFileChange} />
                              <br></br>
                              <button className="buttonOne px-4 py-2 bg-gray-600 text-white rounded mr-2">hochladen</button>
                            </form>
                          </div>
                        </div>
                        <div className="col col-sm-8 flex text-center justify-center items-center">
                        { getBookUploadSuccess && ( 
                        <div className="">
                            <div>
                              <div className="my-10" style={{ minWidth: '300px', minHeight: '50px' }}>
                                <div><h3 className='text-center'>Erfolgreich Hochgeladen</h3></div>
                                <div className=" " style={{ minWidth: '300px', minHeight: '60px' }}>
                                  <div className="profileImg2 bg-blue-200" style={{ maxWidth: '240px', maxHeight: '50px', minWidth: '300px', minHeight: '50px' }}>
                                    <div className='p-5 text-center'>
                                       <p><b>100%</b></p> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          )}
                        </div>
                      </div>
                    </div>

                  </div>


                </>
               
              </div>

            </div>  
      
  </div> 
</div>           
    
  );
}

export default BookCreationView; 
export { default as BookCreationView } from './BookCreationView';