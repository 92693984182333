import './ResetPassword.css';
import api from '../../api/axiosConfig';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import logo from '../../images/shiny_symbol_withcircle1.png';


function ResetPassword() {
    
    const [getIsUnConfirmed, setIsUnConfirmed  ] = useState([false]);
    const [getUserNotExists, setUserNotExists  ] = useState([false]);
    const [getNoPasswordMatch, setNoPasswordMatch  ] = useState([false]);
    const [getToken,setToken,  ] = useState("");
    const [getToken2,setToken2,  ] = useState("");

    const [getThereWasAnError,setThereWasAnError ] = useState([false]);
    const [error, setError] = useState(null);

    const navigate = useNavigate();


    const resetPassword = async (e) => {
      e.preventDefault();
    
      const { target } = e;
    
      const newResetData = {
        userName: getToken2,
        newPassword: target.password.value
      };
    
      const passwordMatch = target.password2.value;
    
      if (passwordMatch === newResetData.newPassword) {
        try {
          const response2 = await api.get(`/api/v1/auth/user/${newResetData.userName}`, {
            headers: { Authorization: `Bearer ${getToken}`, }
          });
    
          const isConfirmed = response2.data.isConfirmed;
    
          if (isConfirmed) {
            if (response2.data != null) {
              const response = await api.post(`/api/v1/auth/resetPassword`, newResetData, {
                headers: { Authorization: `Bearer ${getToken}`, }
              });
    
              if (response.data === true) {
                navigate("/");
              } else {
                setThereWasAnError(true);
              }
            } else {
              setUserNotExists(true);
            }
          } else {
            setIsUnConfirmed(true);
          }
        } catch (error) {
          handleError('Error during password reset:', error);
        }
      } else {
        setNoPasswordMatch(true);
      }
    };
    

    useEffect(() => {
      setUserNotExists(false);
      setIsUnConfirmed(false);
      setThereWasAnError(false);
      setNoPasswordMatch(false);
      setError("");
    }, []);
    
    const location = useLocation();
    
    useEffect(() => {
      // Extract token from the URL
      const token = new URLSearchParams(location.search).get('token');
      const token2 = new URLSearchParams(location.search).get('token2');
    
      if (token && token2) {
        setToken(token);
        setToken2(token2);
      } else {
        handleError('Token not found in the URL');
      }
    }, [location.search]);


    const handleError = (message, error) => {
      console.error(message, error);
      setError('An error occurred. Please try again.');
      };


  return (
<div className="ResetPassword flex justify-center items-center  min-h-screen">
  <div className="container py-5 text-center">
    <div className="row row-col-3">
      <div className="col-sm-4"></div>
      <div className="col-sm-4 mt-20">
        <div className="col-sm-12 flex justify-center">
          <div className="col-sm-2"></div>
          <div className="col-sm-8"><img className="StyleTwo" src={logo} alt="logo" /></div>
          <div className="col-sm-2"></div>
        </div>
        <div>
          <h3>Reset Password</h3>
        </div>

        <form onSubmit={resetPassword} className="text-center items-center justify-center">
          <p>Hier kannst du dein Passwort zurücksetzen</p>
          {error && <div style={{ color: 'red' }}>{error}</div>}
          {getIsUnConfirmed && (<p>Dein Profil ist nicht verifiziert. Bitte verifiziere dein Profil!</p>)}
          {getUserNotExists && (<p>Dein Profil existiert nicht. Bitte registriere Dich!</p>)}
          {getThereWasAnError && (<p>Es tut uns leid. Es gab einen Fehler!</p>)}

          <div className="mt-10 mb-10">
            <h4>{getToken2}</h4>
          </div>

          {getNoPasswordMatch && (<p style={{ color: 'red' }}>Die Passwörter stimmen nicht überein!</p>)}

          <div className="form-outline mb-4">
            <input type="password" id="form2Example22" className="form-control" name="password" placeholder="Neues Passwort" />
            <label className="form-label" htmlFor="form2Example22">Neues Passwort</label>
          </div>

          <div className="form-outline mb-4">
            <input type="password" id="form2Example22" className="form-control" name="password2" placeholder="Neues Passwort"/>
            <label className="form-label" htmlFor="form2Example22">Neues Passwort</label>
          </div>

          <div className="text-center  pt-1 mb-5 pb-1">
            <button className="buttonTextColor text-2xl p-2 -ml-2 px-32 mx-4 btnChange mb-3"
              type="submit">Bestätigen</button>
          </div>
        </form>
      </div>
      <div className="col-sm-4"></div>
    </div>
  </div>
</div>

  

    
    
  );
}

export default ResetPassword; 
export { default as ResetPassword } from './ResetPassword';