import './landingPageLogin.css';

//Components
import { CompLogin } from '../components/landingPage/CompLogin';
import { CompTwoBenefits  } from '../components/landingPage/CompTwoBenefits';
import { CompThreePic } from '../components/landingPage/CompThreePic';
import { CompFourAppFunc } from '../components/landingPage/CompFourAppFunc';
import { CompFiveFAQ } from '../components/landingPage/CompFiveFAQ';
import { Footer } from '../components/navbar&Footer/Footer';
import { Navbar } from '../components/navbar&Footer/Navbar';
import {IntroCompLogin} from '../components/landingPage/IntroCompLogin';
import { CompFreeSpace } from '../components/landingPage/CompFreeSpace';
import { CompFreeSpaceSmall } from '../components/landingPage/CompFreeSpaceSmall';
import { CompMoreInfo } from '../components/landingPage/CompMoreInfo';
import { CompNewsletter } from '../components/landingPage/CompNewsletter';


import { useState } from 'react';


const LandingPageLogin = ({baseUrl}) => {

  const [showPasswordReset, setShowPasswordReset] = useState(false);

  return (
      <div className="LandingPageLogin skin-glassmorphism-background" style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>
          <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='gradient-bg-bluumer'>
                  <div className="component-wrapper">
                    <IntroCompLogin showPasswordReset={showPasswordReset} setShowPasswordReset={setShowPasswordReset}/>
                  </div>
                {showPasswordReset && (
                  <div className="component-wrapper w-full pb-40 mb-40">
                    <CompFreeSpace />
                  </div>
                )}
               { !showPasswordReset && (   
                <>
                  <div className="component-wrapper w-full sm:mb-28 lg:mb-10 lg:mt-80 ">
                  <CompTwoBenefits />
                </div>
                <div className="component-wrapper w-full">
                  <CompNewsletter />
                </div>
                <div className="component-wrapper w-full sm:pb-80">
                  <CompMoreInfo />
                </div>
                <div className="component-wrapper w-full lg:hidden max-sm:pb-80 max-sm:mb-80">
                  <CompFreeSpace />
                </div>
                <div className="component-wrapper w-full sm:hidden max-sm:pb-80  mb-0">
                  <CompFreeSpaceSmall />
                </div>     
                <div className="component-wrapper w-full">
                  <CompFourAppFunc />
                </div>
                <div className="component-wrapper w-full max-md:hidden  2xl:hidden">
                  <CompFreeSpace />
                </div>
                <div className="component-wrapper w-full">
                  <CompNewsletter />
                </div>
                <div className="component-wrapper w-full max-sm:mb-80 max-sm:pb-80 sm:mt-80 mt-80 ">
                  <CompThreePic />
                </div>
                <div className="component-wrapper w-full max-sm:hidden max-sm:pb-80  mb-0">
                  <CompFreeSpaceSmall />
                </div>
                <div className="component-wrapper w-full max-sm:mt-80 sm:mb-28 mb-80 pb-80">
                  <CompFiveFAQ />
                </div>
                </>
                )}
                <div className="component-wrapper w-full sm:hidden max-sm:mb-0  mb-0">
                  <CompFreeSpaceSmall />
                </div>
                <div className="footer-wrapper w-full">
                  <Footer />
                </div>
          </div>
    </div>
  );
}

export default LandingPageLogin; 