import './CompNewsletter.css';


import { useEffect, useState } from 'react';
import logo from '../../images/shiny_symbol_withcircle1.png';



function CompNewsletter() {


  const [showPopUp, setShowPopUp] = useState(false);

  const [error, setError] = useState(null);
  
  
      useEffect(() => {
         setShowPopUp(false);
      }, []);
            

    const handleError = (message, error) => {
      console.error(message, error);
      setError('An error occurred. Please try again.');
      };


  return (
    <div className="CompNewsletter max-sm:pt-40 ">
     <div className="w-full max-sm:pt-80">
            <div className="row row-col-12 text-center m-4">
              	{/*}  {error && <div style={{ color: 'red' }}>{error}</div>}     */}
	    </div>



            <div className='col col-sm-12 w-full  compThreeBGColor'>
                <div className="col col-sm-6 w-full sm:p-8 mb-0 ">
                    <h1 className="mb-8 text-center landingColor gradient-textLanding max-sm:pt-20 font-bold">Newsletter</h1>
                    <p className="mb-4 text-3xl text-white" >Melde dich auf unserem Newsletter an, und erhalte wichtige Infos, Neuigkeiten und exklusive Blog-Artikel!</p>
                    <div className="text-center sm:pb-10">
                      <button onclick={()=> setShowPopUp(true) } className="rm-open-popup inputSize300 text-4xl py-4 px-34 btnLanding hover:text-black hover:bg-yellow-100" type="submit">Jetzt anmelden</button>
                    </div>
		{ showPopUp && (
		    <div>
                   {/*}  <button class="rm-open-popup">Show modal</button>  */}
			</div>
		)}
                </div> 
                <div className="col col-sm-6 max-sm:pb-20 justify-center gradient-textLanding ">
                        <h3 className="mb-8 mt-40 text-center text-white">Sei dabei!</h3>
                        <h3 className="mb-8 text-center text-white">Immer top informiert und bereit Neues zu lernen.</h3>
                </div>
            </div>


         
  </div> 
</div>              
         
    
    
  );
}

export default CompNewsletter; 
export { default as CompNewsletter } from './CompNewsletter';