import { Link } from "react-router-dom";

import socPic1 from '../../images/facebook1.svg'
import socPic2 from  '../../images/tiktok3.svg'
import socPic3 from '../../images/instagram_96px_black.png'
import block from '../../images/shiny_symbol_.png'



const Footer = () => {

    return (
        <div className="z-40 pb-24 -mb-20 justify-center items-center skin-glassmorphism-navCard mt-80">
            <div
            className="flex-wrap grid grid-rows-1 max-sm:hidden grid-cols-12 gap-5 w-full place-items-center bg-left-top bg-no-repeat background-image-small"
            style={{
                backgroundImage: `url(${block})`, backgroundSize: 170 , backgroundPositionY: 60
            }}>
                <div className="col-span-12  p-5">
                        <div className="justify-start items-start mt-10">
                        <ul className="lg:flex list-none text-2xl justify-between items-center">
                            
                            <li className="mx-4 cursor-pointer mb-3"><Link to="/">Home</Link></li>
			                <li className="mx-4 cursor-pointer mb-3"><a href="http://www.bluumer.com/legalPrivacyContact/PrivacyPolicy.pdf" target="_blank" rel="noreferrer" download>Datenschutzrichtlinie</a></li>
                            <li className="mx-4 cursor-pointer mb-3"><a href="http://www.bluumer.com/legalPrivacyContact/TermsOfService.pdf" target="_blank" rel="noreferrer" download>AGB</a></li>
                            <li className="mx-4 cursor-pointer mb-3"><Link to="/contact">Kontakt</Link></li>
                            <li className="mx-4 cursor-pointer mb-3"><Link to="/contact">Impressum</Link></li>
                        </ul>
                        </div>
                    </div>
                <div className="col-span-12 p-5">
                <div className="flex flex-wrap justify-start items-start">
                        <a href="https://www.instagram.com/bluumer.official/" target="_blank" rel="noreferrer">
                            <div className="bg-white rounded-full p-2 h-16 w-16 flex justify-center items-center transition duration-500 ease-in-out transform hover:scale-110 sm:h-20 sm:w-20 sm:mt-2 sm:mx-2">
                            <img className="h-10 sm:h-12" alt="Icon" src={socPic3} />
                            </div>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61564351212414" target="_blank" rel="noreferrer">
                            <div className="bg-white rounded-full p-2 h-16 w-16 flex justify-center items-center transition duration-500 ease-in-out transform hover:scale-110 sm:h-20 sm:w-20 sm:mt-2 sm:mx-2">
                            <img className="h-10 sm:h-12 " alt="Icon" src={socPic1} />
                            </div>
                        </a>
                        <a href="https://www.tiktok.com/@bluumer_official?lang=de-DE" target="_blank" rel="noreferrer">
                            <div className="bg-white rounded-full p-2 h-16 w-16 flex justify-center items-center transition duration-500 ease-in-out transform hover:scale-110 sm:h-20 sm:w-20 sm:mt-2 sm:mx-2">
                            <img className="h-10 sm:h-12" alt="Icon" src={socPic2} />
                            </div>
                        </a>
                        </div>
                    </div>
                    <div className="col-span-12 mb-5 mt-20 text-center px-10">
                            <div className="text-white text-xs sm:text-0.5rem">
                        <p>Copyright ALEVENI GmbH 2024</p>
                            </div>
                    </div>
        </div>

        <div
            className="flex-wrap grid grid-rows-1 sm:hidden grid-cols-12 gap-5 w-full place-items-center bg-left-top bg-no-repeat background-image-small">
                <div className="col-span-12  p-5">
                        <div className="justify-start items-start mt-10">
                        <ul className="lg:flex list-none text-2xl justify-between items-center">
                            
                            <li className="mx-4 cursor-pointer mb-3"><Link to="/">Home</Link></li>
			                <li className="mx-4 cursor-pointer mb-3"><a href="http://www.bluumer.com/legalPrivacyContact/PrivacyPolicy.pdf" target="_blank" rel="noreferrer" download>Datenschutzrichtlinie</a></li>
                            <li className="mx-4 cursor-pointer mb-3"><a href="http://www.bluumer.com/legalPrivacyContact/TermsOfService.pdf" target="_blank" rel="noreferrer" download>AGB</a></li>
                            <li className="mx-4 cursor-pointer mb-3"><Link to="/contact">Kontakt</Link></li>
                            <li className="mx-4 cursor-pointer mb-3"><Link to="/contact">Impressum</Link></li>
                            
                        </ul>
                        </div>
                    </div>
                <div className="col-span-12 p-5">
                <div className="flex flex-wrap justify-start items-start">
                        <a href="https://www.instagram.com/bluumer.official/" target="_blank" rel="noreferrer">
                            <div className="bg-white rounded-full p-2 h-16 w-16 flex justify-center items-center transition duration-500 ease-in-out transform hover:scale-110 sm:h-20 sm:w-20 sm:mt-2 sm:mx-2">
                            <img className="h-10 sm:h-12" alt="Icon" src={socPic3} />
                            </div>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61564351212414" target="_blank" rel="noreferrer">
                            <div className="bg-white rounded-full  max-sm:mx-3 p-2 h-16 w-16 flex justify-center items-center transition duration-500 ease-in-out transform hover:scale-110 sm:h-20 sm:w-20 sm:mt-2 sm:mx-2">
                            <img className="h-10 sm:h-12" alt="Icon" src={socPic1} />
                            </div>
                        </a>
                        <a href="https://www.tiktok.com/@bluumer_official?lang=de-DE" target="_blank" rel="noreferrer">
                            <div className="bg-white rounded-full p-2 h-16 w-16 flex justify-center items-center transition duration-500 ease-in-out transform hover:scale-110 sm:h-20 sm:w-20 sm:mt-2 sm:mx-2">
                            <img className="h-10 sm:h-12" alt="Icon" src={socPic2} />
                            </div>
                        </a>
                        </div>
                    </div>
                    <div   className="col-span-12 p-20 bg-center"    style={{backgroundImage: `url(${block})`, backgroundSize: 100 }}></div>
                    <div className="col-span-12 mb-5 mt-20 text-center px-10">
                            <div className="text-white text-xs sm:text-0.5rem">
                        <p>Copyright ALEVENI GmbH 2024</p>
                            </div>
                    </div>
        </div>
</div>
    );
}

export default Footer;

export { default as Footer } from './Footer';


