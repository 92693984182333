import './CompThreePic.css';

import Pic1 from '../../images/glühbirne.jpg';
import Pic2 from '../../images/landingAuthor2.jpg';
import Pic3 from '../../images/landingPublisher2.jpg';
import Pic4 from '../../images/landingCover2.jpg';


function CompThreePic() {
  return (
    <div className="CompThreePic text-center mt-20 pt-20">
      <div className="py-10">
        <h2 className="text-white text-4xl lg:text-5xl font-bold mb-10">
          Dein Tor zu qualitativen Dienstleistungen, Expertise und unentdeckten Bestsellern.
        </h2>
      </div>

      <div className="flex flex-wrap justify-center">
        {[Pic1, Pic2, Pic3, Pic4].map((pic, index) => (
          <div key={index} className="card text-white m-4 p-4 w-full sm:w-1/2 lg:w-1/3 compThreeBGColorGray rounded-xl shadow-lg transform transition-transform hover:scale-105">
            <img src={pic} alt={`Card ${index + 1}`} className="w-full h-80 object-cover opacity-75 rounded-t-xl" />
            <div className="text-center mt-4">
              <h4 className="text-lg font-semibold">
                {index === 0 && 'Verwandle als Autor dein rohes Manuskript zu einem hochwertigen Buch. An einem Ort, vom Traum zum Bestseller.'}
                {index === 1 && 'Teile als Lektor dein Fachwissen und Dienstleistung mit aufstrebenden Schriftstellern oder finde einzigartige Geschichten als Testleser. Deine Leseeindrücke sind von unschätzbarem Wert für Autoren.'}
                {index === 2 && 'Verleihe als Cover-Designer Manuskripten ein unverwechselbares Antlitz. Ein Kunstwerk, welches beim Anblick schon Leselust macht.'}
                {index === 3 &&  'Als Verlag entdeckst du vielversprechende Manuskripte und neue Talente. Eine Erweiterung deines Pools an aufstrebenden Autoren.'}
              </h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CompThreePic; 
export { default as CompThreePic } from './CompThreePic';